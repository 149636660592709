import currency from 'currency.js';

const Number = {
  format: (value, locales = 'pt-BR', options = {}) => {
    if (!parseFloat(value, 10)) {
      return '0';
    }
    return new Intl.NumberFormat(locales, {
      maximumFractionDigits: 2,
      ...options
    }).format(value);
  },
  currencyFormat: (value, locales = 'pt-BR', options = {currency: 'BRL'}) => {
    if (!value)
      return 'R$ 0,00';

    if (typeof value === 'string')
      value = Number.stringToFloat(value);

    let currentValue = value;
    if (!parseInt(value, 10)) {
      currentValue = 0;
    }
    return new Intl.NumberFormat(locales, {
      ...options,
      maximumFractionDigits: 2,
      style: 'currency'
    }).format(currentValue);
  },
  stringToFloat: value => {
    if (!value)
      return 0

    return currency(value, {separator: '.', decimal: ','}).value;
  },
  currencyToFloat: value => {
    if (!value) return 0;

    return currency(value, {separator: '.', decimal: ','}).value;
  },
  regraDeTresPorcentagem: (valor = 0, valorTotal = 0, format = false) => {
    if (!valorTotal || valorTotal === 0) return 0;
    const resultado = (Number.stringToFloat(valor) * 100) / Number.stringToFloat(valorTotal);

    if (format)
      return Number.format(resultado);

    return resultado;
  },
  regraDeTresValor: (valor = 0, porcentagem = 0, format = false) => {
    const resultado = Number.stringToFloat(porcentagem) * (Number.stringToFloat(valor) / 100);

    if (format)
      return Number.currencyFormat(resultado);

    return resultado;
  },
  divide: (valor, divisor) => {
    if (!valor || !divisor || divisor === 0)
      return 0;

    return Number.stringToFloat(valor) / Number.stringToFloat(divisor);
  },
  safeParseInt: value => {
    if (!value || isNaN(value)) return 0;

    return parseInt(value);
  },
  safeParseFloat: value => {
    if (!value || isNaN(value)) return 0;

    return parseFloat(value);
  },
};

export default Number;
