import React, {useEffect, useState} from 'react';
import {UPDATE_SITUACAO_PROPOSTA} from '../../graphql/mutation';
import Board from 'react-trello';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {CircularProgress, Grid, Paper} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {useLazyQuery, useMutation} from '@apollo/client';
import {Field, Formik} from 'formik';
import {
  FIND_ALL_PROPOSTA_BY_COMERCIAL,
  FIND_TOTAIS_PROPOSTAS_BY_COMERCIAL,
} from '../../graphql/queries';
import {ButtonComponent} from '../../components/mui-button';
import {SelectAnunciantes} from '../../containers/selects/anunciantes';
import Card from './card';
import Lane from './lane';
import {toastNotification} from '../../components/toastify';
import MotivoReprovacaoProposta from '../../containers/modais/cadastro-proposta/motivo-reprovacao-proposta';
import InputDate from '../../components/mui-input-date';
import Data from '../../utils/data';

const optionSituacao = [
  {value: 'ORCAMENTO', label: 'Prospecção'},
  {value: 'EM_NEGOCIACAO', label: 'Negociação'},
  {value: 'NEGOCIACAO_AVANCADA', label: 'Negociação avançada'},
  {value: 'AGUARDANDO_PI', label: 'Aguardando PI'},
  {value: 'AGUARDANDO_APROVACAO', label: 'Aguardando aprovação do diretor'},
  {value: 'APROVADO', label: 'Aprovado'},
  {value: 'REPROVADO', label: 'Reprovado'},
];

const pageable = {
  pageSize: 20,
  orderBy: [
    {
      campo: 'dataAlteracao',
      ordem: 'DESC',
    },
    {
      campo: 'anunciante.nomeFantasia',
      ordem: 'ASC',
    },
    {
      campo: 'id',
      ordem: 'ASC',
    },
  ],
};

const ConsultaProposta = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const {token, role} = useSelector((state) => state.Auth);
  const {filtrosProposta} = useSelector((store) => store.Proposta);

  const CONSULTA_QUERY = FIND_ALL_PROPOSTA_BY_COMERCIAL;
  const TOTAL_QUERY = FIND_TOTAIS_PROPOSTAS_BY_COMERCIAL;

  const [proposta, setProposta] = useState(undefined);
  const [filtros, setFiltros] = useState(filtrosProposta.filtros);
  const [searchDTO, setSearchDTO] = useState(filtros);
  const [prospeccao, setProspeccao] = useState([]);
  const [negociacao, setNegociacao] = useState([]);
  const [negociacaoAvancada, setNegociacaoAvancada] = useState([]);
  const [aguardandoPi, setAguardandoPi] = useState([]);
  const [aguardandoAprovacao, setAguardandoAprovacao] = useState([]);
  const [reprovado, setReprovado] = useState([]);
  const [aprovado, setAprovado] = useState([]);
  const [totais, setTotais] = useState([]);
  const [data, setData] = useState({lanes: [{id: '', cards: [{id: ''}]}]});
  const [paginaProspeccao, setPaginaProspeccao] = useState(0);
  const [paginaNegociacao, setPaginaNegociacao] = useState(0);
  const [paginaNegociacaoAvancada, setPaginaNegociacaoAvancada] = useState(0);
  const [paginaAguardandoPi, setPaginaAguardandoPi] = useState(0);
  const [paginaAguardandoAprovacao, setPaginaAguardandoAprovacao] = useState(0);
  const [paginaReprovado, setPaginaReprovado] = useState(0);
  const [paginaAprovado, setPaginaAprovado] = useState(0);
  const [drag, setDrag] = useState(true);
  const [openMotivo, setOpenMotivo] = useState(false);
  const [motivoInformado, setMotivoInformado] = useState(false);

  const [buscarProspeccao, prospeccaoQuery] = useLazyQuery(CONSULTA_QUERY);
  const [buscarNegociacao, negociacaoQuery] = useLazyQuery(CONSULTA_QUERY);
  const [buscarNegociacaoAvancada, negociacaoAvancadaQuery] = useLazyQuery(CONSULTA_QUERY);
  const [buscarAguardandoPi, aguardandoPiQuery] = useLazyQuery(CONSULTA_QUERY);
  const [buscarAguardandoAprovacao, aguardandoAprovacaoQuery] = useLazyQuery(CONSULTA_QUERY);
  const [buscarReprovado, reprovadoQuery] = useLazyQuery(CONSULTA_QUERY);
  const [buscarAprovado, aprovadoQuery] = useLazyQuery(CONSULTA_QUERY);
  const [buscarTotais, totaisQuery] = useLazyQuery(TOTAL_QUERY);

  const [updateSituacaoProposta] = useMutation(UPDATE_SITUACAO_PROPOSTA);

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      refetch();
    }
  };

  const components = {
    Card: Card,
    LaneHeader: Lane,
  };

  const handlePaginacao = (pagina, lane) => {
    onPageChange(lane);
    return new Promise(function (resolve, reject) {
      resolve();
    });
  };

  const onPageChange = (lane) => {
    switch (lane) {
      case 'ORCAMENTO':
        return (
          !prospeccaoQuery.loading &&
          !prospeccaoQuery.data?.propostas?.last &&
          setPaginaProspeccao(paginaProspeccao + 1)
        );
      case 'EM_NEGOCIACAO':
        return (
          !negociacaoQuery.loading &&
          !negociacaoQuery.data?.propostas?.last &&
          setPaginaNegociacao(paginaNegociacao + 1)
        );
      case 'NEGOCIACAO_AVANCADA':
        return (
          !negociacaoAvancadaQuery.loading &&
          !negociacaoAvancadaQuery.data?.propostas?.last &&
          setPaginaNegociacaoAvancada(paginaNegociacaoAvancada + 1)
        );
      case 'AGUARDANDO_PI':
        return (
          !aguardandoPiQuery.loading &&
          !aguardandoPiQuery.data?.propostas?.last &&
          setPaginaAguardandoPi(paginaAguardandoPi + 1)
        );
      case 'AGUARDANDO_APROVACAO':
        return (
          !aguardandoAprovacaoQuery.loading &&
          !aguardandoAprovacaoQuery.data?.propostas?.last &&
          setPaginaAguardandoAprovacao(paginaAguardandoAprovacao + 1)
        );
      case 'APROVADO':
        return (
          !aprovadoQuery.loading &&
          !aprovadoQuery.data?.propostas?.last &&
          setPaginaAprovado(paginaAprovado + 1)
        );
      case 'REPROVADO':
        return (
          !reprovadoQuery.loading &&
          !reprovadoQuery?.data?.propostas?.last &&
          setPaginaReprovado(paginaReprovado + 1)
        );
      default:
        return;
    }
  };

  const refetch = () => {
    setSearchDTO({
      ...filtros,
      dataInicial: Data.dataFormat(filtros.dataInicial, 'YYYY-MM-DD')
    });
  };

  //Função que busca ds totais
  const handleBuscarTotais = () => {
    buscarTotais({
      variables: {
        anuncianteId: searchDTO?.anunciante?.value,
        comercialId: searchDTO?.comercial?.value,
        data: searchDTO.dataInicial,
      },
    });
  };

  const handleBuscarProspeccao = () => {
    buscarProspeccao({
      variables: {
        pageable: {
          pageNumber: paginaProspeccao,
          ...pageable,
        },
        searchDTO: {
          anuncianteId: searchDTO?.anunciante?.value,
          comercialId: searchDTO?.comercial?.value,
          dataInicial: searchDTO.dataInicial,
          orcamentoSituacao: 'ORCAMENTO',
        },
      },
    });
  };

  const handleBuscarNegociacao = () => {
    buscarNegociacao({
      variables: {
        pageable: {
          pageNumber: paginaNegociacao,
          ...pageable,
        },
        searchDTO: {
          anuncianteId: searchDTO?.anunciante?.value,
          comercialId: searchDTO?.comercial?.value,
          dataInicial: searchDTO.dataInicial,
          orcamentoSituacao: 'EM_NEGOCIACAO',
        },
      },
    });
  };
  const handleBuscarNegociacaoAvancada = () => {
    buscarNegociacaoAvancada({
      variables: {
        pageable: {
          pageNumber: paginaNegociacaoAvancada,
          ...pageable,
        },
        searchDTO: {
          anuncianteId: searchDTO?.anunciante?.value,
          comercialId: searchDTO?.comercial?.value,
          dataInicial: searchDTO.dataInicial,
          orcamentoSituacao: 'NEGOCIACAO_AVANCADA',
        },
      },
    });
  };
  //Função que busca ds dados de aguardandoPi
  const handleBuscarAguardandoPi = () => {
    buscarAguardandoPi({
      variables: {
        pageable: {
          pageNumber: paginaAguardandoPi,
          ...pageable,
        },
        searchDTO: {
          anuncianteId: searchDTO?.anunciante?.value,
          comercialId: searchDTO?.comercial?.value,
          dataInicial: searchDTO.dataInicial,
          orcamentoSituacao: 'AGUARDANDO_PI',
        },
      },
    });
  };
  //Função que busca ds dados de aguardandoAprovacao
  const handleBuscarAguardandoAprovacao = () => {
    buscarAguardandoAprovacao({
      variables: {
        pageable: {
          pageNumber: paginaAguardandoAprovacao,
          ...pageable,
        },
        searchDTO: {
          anuncianteId: searchDTO?.anunciante?.value,
          comercialId: searchDTO?.comercial?.value,
          dataInicial: searchDTO.dataInicial,
          orcamentoSituacao: 'AGUARDANDO_APROVACAO',
        },
      },
    });
  };
  //Função que busca ds dados dos Aprovados
  const handleBuscarAprovado = () => {
    buscarAprovado({
      variables: {
        pageable: {
          pageNumber: paginaAprovado,
          ...pageable,
        },
        searchDTO: {
          anuncianteId: searchDTO?.anunciante?.value,
          comercialId: searchDTO?.comercial?.value,
          dataInicial: searchDTO.dataInicial,
          orcamentoSituacao: 'APROVADO',
        },
      },
    });
  };
  //Função que busca ds dados dos Reprovados
  const handleBuscarReprovado = () => {
    buscarReprovado({
      variables: {
        pageable: {
          pageNumber: paginaReprovado,
          ...pageable,
        },
        searchDTO: {
          anuncianteId: searchDTO?.anunciante?.value,
          comercialId: searchDTO?.comercial?.value,
          dataInicial: searchDTO.dataInicial,
          orcamentoSituacao: 'REPROVADO',
        },
      },
    });
  };
  //função que atualiza os dados no banco de dados e as respectivas lanes na tela

  const handleDragEnd = (cardId, sourceLaneId, targetLaneId) => {
    if (sourceLaneId === 'APROVADO' || targetLaneId === 'APROVADO' ||
      sourceLaneId === 'AGUARDANDO_APROVACAO') {
      toastNotification({
        message:
          'Não é possível arrastar negociações para esta coluna ou remover propostas dela!',
        type: 'warning',
      });
      return false;
    }
    if (targetLaneId === 'AGUARDANDO_APROVACAO') {
      navigate(`${cardId}/editar`);
    }
    return true;
  };

  useEffect(() => {
    if (!motivoInformado || !proposta?.id) return;

    handleMoveAcrossLanes(proposta.situacao, 'REPROVADO', proposta.id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [motivoInformado]);

  const handleMoveAcrossLanes = (fromLaneId, toLaneId, cardId) => {
    if (toLaneId === fromLaneId) return;

    if (fromLaneId === 'APROVADO' || toLaneId === 'APROVADO' ||
      fromLaneId === 'AGUARDANDO_APROVACAO' || toLaneId === 'AGUARDANDO_APROVACAO') {
      return;
    }

    setProposta({id: cardId, situacao: fromLaneId});
    if (toLaneId === 'REPROVADO' && !motivoInformado) {
      setOpenMotivo(true);
      return;
    }

    setDrag(false);
    updateSituacaoProposta({
      variables: {
        propostaId: cardId,
        situacao: toLaneId,
      },
    })
      .then(() => {
        setMotivoInformado(false);
        handleBuscarTotais();
        let proposta;
        switch (fromLaneId) {
          case 'ORCAMENTO': {
            proposta = prospeccao.filter((o) => o.id === cardId);
            setProspeccao((p) => p.filter((o) => o.id !== cardId));
            break;
          }
          case 'EM_NEGOCIACAO': {
            proposta = negociacao.filter((o) => o.id === cardId);
            setNegociacao((n) => n.filter((o) => o.id !== cardId));
            break;
          }
          case 'NEGOCIACAO_AVANCADA': {
            proposta = negociacaoAvancada.filter((o) => o.id === cardId);
            setNegociacaoAvancada((n) => n.filter((o) => o.id !== cardId));
            break;
          }
          case 'AGUARDANDO_PI': {
            proposta = aguardandoPi.filter((o) => o.id === cardId);
            setAguardandoPi((a) => a.filter((o) => o.id !== cardId));
            break;
          }
          case 'AGUARDANDO_APROVACAO': {
            proposta = aguardandoAprovacao.filter((o) => o.id === cardId);
            setAguardandoAprovacao((a) => a.filter((o) => o.id !== cardId));
            break;
          }
          case 'APROVADO': {
            proposta = aprovado.filter((o) => o.id === cardId);
            setAprovado((a) => a.filter((o) => o.id !== cardId));
            break;
          }
          case 'REPROVADO': {
            proposta = reprovado.filter((o) => o.id === cardId);
            setReprovado((r) => r.filter((o) => o.id !== cardId));
            break;
          }
          default:
            break;
        }

        switch (toLaneId) {
          case 'ORCAMENTO':
            setProspeccao((p) => [...proposta, ...p]);
            break;
          case 'EM_NEGOCIACAO':
            setNegociacao((n) => [...proposta, ...n]);
            break;
          case 'NEGOCIACAO_AVANCADA':
            setNegociacaoAvancada((n) => [...proposta, ...n]);
            break;
          case 'AGUARDANDO_PI':
            setAguardandoPi((a) => [...proposta, ...a]);
            break;
          case 'AGUARDANDO_APROVACAO':
            setAguardandoAprovacao((a) => [...proposta, ...a]);
            break;
          case 'APROVADO':
            setAprovado((a) => [...proposta, ...a]);
            break;
          case 'REPROVADO':
            setReprovado((r) => [...proposta, ...r]);
            break;
          default:
            break;
        }
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setDrag(true));
  };
  // as funções anteriores são executadas quando a mudança na paginação ou filtros
  useEffect(() => {
    handleBuscarTotais();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDTO]);

  useEffect(() => {
    handleBuscarProspeccao();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDTO, paginaProspeccao]);

  useEffect(() => {
    handleBuscarNegociacao();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDTO, paginaNegociacao]);
  useEffect(() => {
    handleBuscarNegociacaoAvancada();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDTO, paginaNegociacaoAvancada]);

  useEffect(() => {
    handleBuscarAguardandoPi();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDTO, paginaAguardandoPi]);

  useEffect(() => {
    handleBuscarAguardandoAprovacao();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDTO, paginaAguardandoAprovacao]);

  useEffect(() => {
    handleBuscarAprovado();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDTO, paginaAprovado]);

  useEffect(() => {
    handleBuscarReprovado();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDTO, paginaReprovado]);

  useEffect(() => {
    if (totaisQuery.loading) return;

    setTotais(totaisQuery.data?.totais || []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totaisQuery]);

  useEffect(() => {
    if (prospeccaoQuery.loading) return;

    if (paginaProspeccao === 0)
      setProspeccao(prospeccaoQuery?.data?.propostas?.content || []);
    else
      setProspeccao((prev) => [
        ...prev,
        ...prospeccaoQuery?.data?.propostas?.content,
      ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prospeccaoQuery]);

  useEffect(() => {
    if (negociacaoQuery.loading) return;

    if (paginaNegociacao === 0)
      setNegociacao(negociacaoQuery?.data?.propostas?.content || []);
    else
      setNegociacao((prev) => [
        ...prev,
        ...negociacaoQuery?.data?.propostas?.content,
      ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [negociacaoQuery]);
  useEffect(() => {
    if (negociacaoAvancadaQuery.loading) return;

    if (paginaNegociacaoAvancada === 0)
      setNegociacaoAvancada(
        negociacaoAvancadaQuery?.data?.propostas?.content || [],
      );
    else
      setNegociacaoAvancada((prev) => [
        ...prev,
        ...negociacaoAvancadaQuery?.data?.propostas?.content,
      ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [negociacaoAvancadaQuery]);

  useEffect(() => {
    if (aguardandoPiQuery.loading) return;

    if (paginaAguardandoPi === 0)
      setAguardandoPi(aguardandoPiQuery?.data?.propostas?.content || []);
    else
      setAguardandoPi((prev) => [
        ...prev,
        ...aguardandoPiQuery?.data?.propostas?.content,
      ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aguardandoPiQuery]);

  useEffect(() => {
    if (aguardandoAprovacaoQuery.loading) return;

    if (paginaAguardandoAprovacao === 0)
      setAguardandoAprovacao(aguardandoAprovacaoQuery?.data?.propostas?.content || []);
    else
      setAguardandoAprovacao((prev) => [
        ...prev,
        ...aguardandoAprovacaoQuery?.data?.propostas?.content,
      ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aguardandoAprovacaoQuery]);

  useEffect(() => {
    if (aprovadoQuery.loading) return;

    if (paginaAprovado === 0)
      setAprovado(aprovadoQuery?.data?.propostas?.content || []);
    else
      setAprovado((prev) => [
        ...prev,
        ...aprovadoQuery?.data?.propostas?.content,
      ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aprovadoQuery]);

  useEffect(() => {
    if (reprovadoQuery.loading) return;

    if (paginaReprovado === 0)
      setReprovado(reprovadoQuery?.data?.propostas?.content || []);
    else
      setReprovado((prev) => [
        ...prev,
        ...reprovadoQuery?.data?.propostas?.content,
      ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reprovadoQuery]);

  useEffect(() => {
    setData({
      lanes: [
        {
          id: optionSituacao[0].value,
          label: optionSituacao[0]?.label,
          total:
            totais?.filter((t) => t.situacao === optionSituacao[0].value)[0]
              ?.quantidadeOrcamentos || 0,
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.0)',
            padding: 0,
          },
          sx: {
            titleStyle: {
              borderBottom: '2px solid #007BE1',
              width: '240px',
              margin: '0px 15px 16px 15px',
            },
          },
          cards: prospeccao?.map((item, index) => {
            return {
              id: item?.id || '',
              data: {...item, role},
              token: token,
            };
          }),
        },
        {
          id: optionSituacao[1].value || '',
          label: optionSituacao[1]?.label,
          total:
            totais?.filter((t) => t.situacao === optionSituacao[1].value)[0]
              ?.quantidadeOrcamentos || 0,
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.0)',
            padding: 0,
            pointerEvent: negociacaoQuery.loading ? 'none' : 'all',
          },
          sx: {
            titleStyle: {
              borderBottom: '2px solid #2300F9',
              width: '240px',
              margin: '0px 15px 16px 15px',
            },
          },
          cards: negociacao.map((item, index) => {
            return {
              id: item?.id || '',
              data: {...item, role},
              token: token,
            };
          }),
        },
        {
          id: optionSituacao[2].value || '',
          label: optionSituacao[2]?.label,
          total:
            totais?.filter((t) => t.situacao === optionSituacao[2].value)[0]
              ?.quantidadeOrcamentos || 0,
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.0)',
            padding: 0,
            pointerEvent: negociacaoAvancadaQuery.loading ? 'none' : 'all',
          },
          sx: {
            titleStyle: {
              borderBottom: '2px solid #2300F9',
              width: '240px',
              margin: '0px 15px 16px 15px',
            },
          },
          cards: negociacaoAvancada.map((item, index) => {
            return {
              id: item?.id || '',
              data: {...item, role},
              token: token,
            };
          }),
        },
        {
          id: optionSituacao[4].value || '',
          label: optionSituacao[4].label,
          total:
            totais?.filter((t) => t.situacao === optionSituacao[4].value)[0]
              ?.quantidadeOrcamentos || 0,
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.0)',
            padding: 0,
            pointerEvent: aguardandoAprovacaoQuery.loading ? 'none' : 'all',
          },
          sx: {
            titleStyle: {
              borderBottom: '2px solid #2300F9',
              width: '240px',
              margin: '0px 15px 16px 15px',
              maxWidth: '100%',
              whiteSpace: 'break-spaces',
            },
            labelTitleStyle: {
              lineHeight: '18px'
            },
          },
          cards: aguardandoAprovacao.map((item, index) => {
            return {
              id: item?.id || '',
              data: {...item, role},
              token: token,
            };
          }),
        },
        {
          id: optionSituacao[5].value || '',
          label: optionSituacao[5].label,
          total:
            totais?.filter((t) => t.situacao === optionSituacao[5].value)[0]
              ?.quantidadeOrcamentos || 0,
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.0)',
            padding: 0,
            pointerEvent: aprovadoQuery.loading ? 'none' : 'all',
          },
          sx: {
            titleStyle: {
              borderBottom: '2px solid #10C571',
              width: '240px',
              margin: '0px 15px 16px 15px',
            },
          },
          cards: aprovado.map((item, index) => {
            return {
              id: item?.id || '',
              data: {...item, role},
              token: token,
            };
          }),
        },
        {
          id: optionSituacao[6].value || '',
          label: optionSituacao[6].label,
          total:
            totais?.filter((t) => t.situacao === optionSituacao[6].value)[0]
              ?.quantidadeOrcamentos || 0,
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.0)',
            padding: 0,
          },
          sx: {
            titleStyle: {
              borderBottom: '2px solid #FF5252',
              width: '240px',
              margin: '0px 15px 16px 15px',
            },
          },
          cards: reprovado.map((item, index) => {
            return {
              id: item?.id || '',
              data: {...item, role},
              token: token,
            };
          }),
        },
      ],
    });
    setDrag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    prospeccao,
    negociacao,
    negociacaoAvancada,
    aguardandoPi,
    aguardandoAprovacao,
    aprovado,
    reprovado,
    totais,
  ]);

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);

    return () => window.removeEventListener('keyup', handleKeyUp);

    // eslint-disable-next-line
  }, [filtros]);

  return (
    <Grid id="page-container" container sx={{paddingBottom: 0}}>
      <Grid item xs={12}>
        <Formik initialValues={filtros} enableReinitialize>
          <Paper id="filtro-paper">
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <InputDate
                  label="Data"
                  selected={filtros.dataInicial}
                  dateFormat="MMMM/yyyy"
                  showMonthYearPicker
                  onChange={(e) => {
                    setFiltros({...filtros, dataInicial: e});
                  }}
                  isClearable
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={SelectAnunciantes}
                  label="Anunciante"
                  name="anunciante"
                  onChange={(e) =>
                    setFiltros({...filtros, anunciante: e || null})
                  }
                  isClearable
                />
              </Grid>
              <Grid item xs={2} style={{marginLeft: 'auto'}}>
                <ButtonComponent
                  sx={styles.button}
                  value={'Filtrar'}
                  loading={totaisQuery.loading}
                  onClick={refetch}
                />
              </Grid>
            </Grid>
          </Paper>
        </Formik>
      </Grid>
      <Grid item xs={12} sx={styles.funilContainer}>
        <Board
          data={data}
          className={classes.containerBoard}
          draggable
          laneDraggable={false}
          cardDragClass={classes.cardDrag}
          onCardClick={(cardId) => navigate(`${cardId}/editar`)}
          onCardMoveAcrossLanes={handleMoveAcrossLanes}
          handleDragEnd={handleDragEnd}
          hideCardDeleteIcon
          onLaneScroll={handlePaginacao}
          cardDraggable={drag}
          components={components}
        />
        <Grid container sx={styles.loadingContainer}>
          <Grid item xs={2.4} sx={styles.loadingIconContainer}>
            {prospeccaoQuery.loading && (
              <CircularProgress
                width={30}
                height={30}
              />
            )}
          </Grid>
          <Grid item xs={2.4} sx={styles.loadingIconContainer}>
            {negociacaoQuery.loading && (
              <CircularProgress
                width={30}
                height={30}
              />
            )}
          </Grid>
          <Grid item xs={2.4} sx={styles.loadingIconContainer}>
            {negociacaoAvancadaQuery.loading && (
              <CircularProgress
                width={30}
                height={30}
              />
            )}
          </Grid>
          <Grid item xs={2.4} sx={styles.loadingIconContainer}>
            {aguardandoPiQuery.loading && (
              <CircularProgress
                width={30}
                height={30}
              />
            )}
          </Grid>
          <Grid item xs={2.4} sx={styles.loadingIconContainer}>
            {aprovadoQuery.loading && (
              <CircularProgress
                width={30}
                height={30}
              />
            )}
          </Grid>
          <Grid item xs={2.4} sx={styles.loadingIconContainer}>
            {reprovadoQuery.loading && (
              <CircularProgress
                width={30}
                height={30}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <MotivoReprovacaoProposta
        isOpen={openMotivo}
        proposta={proposta}
        onSave={() => {
          setMotivoInformado(true);
          setOpenMotivo(false);
        }}
        onClose={() => {
          setProposta(undefined);
          setOpenMotivo(false);
          setMotivoInformado(false);
          refetch && refetch();
        }}
      />
    </Grid>
  );
};

const styles = {
  containerProposta: {
    position: 'relative',
    padding: ' 0px',
    overflow: 'hidden',
    minHeight: 'calc(100vh - 61px)',
    height: '100%',
  },
  filtroContainer: {
    marginBottom: 15,
    backgroundColor: '#fff',
    padding: '20px 15px',
    borderRadius: '6px',

    '& .button': {
      marginTop: 24,
    },
    '& .react-datepicker-popper': {
      zIndex: 9,
    },
    '& .input-filtro': {
      marginBottom: 0,
    },
    '& .select': {
      marginBottom: 0,

      '& > div': {
        marginBottom: 0,
      },
    },
    '& .react-select__menu': {
      zIndex: 999,
    },
  },
  rowFiltroContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    maxWidth: '185px',
    minWidth: '90px !important',
  },
  loadingContainer: {
    position: 'aboslute',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: 0,
    top: -4,
    minWidth: '1270px',
  },
  loadingIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '20%',
    marginTop: '-20%',
  },
  filtrosContainer: {
    maxHeight: '100px',
    minHeight: '100px'
  },
  funilContainer: {
    position: 'relative',
    overflowX: 'auto',
    height: theme => `calc(100vh - 88px - ${theme.toolbarHeight})`,
    paddingTop: '8px'
  }
};

const useStyles = makeStyles(() => ({
  cardDrag: {
    boxShadow: '3px 4px 13px rgba(0, 131, 230, 0.5)',
  },
  containerBoard: {
    backgroundColor: 'rgba(0, 0, 0, 0.0)',
    height: '100%',
    minWidth: '1800px',
    padding: '0 15px !important',
    overflowY: 'visible !important',
    display: 'grid',

    '& .react-trello-lane ': {
      maxHeight: 'calc(100vh - 173px) !important',
      display: 'flex !important',
      margin: 0,
      width: '280px',
      alignItems: 'center',
      overflowY: 'auto',
      overflowX: 'hidden',

      '& >div': {
        '&::-webkit-scrollbar': {
          background: 'rgba(0, 0, 0, 0.0) !important',
          width: '0px',
        },
        '&:hover': {
          '&::-webkit-scrollbar': {
            background: 'rgba(0, 0, 0, 0.0) !important',
            minWidth: '6px !important',
            margin: '-16px',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '20px',
            backgroundColor: '#dddfe0',
          },
        },
      },
    },
    '& .react-trello-card ': {
      minWidth: '240px',
      padding: '20px',
      borderRadius: '12px',
      margin: '0px 13px 16px 13px',

      '& >Header': {
        border: 'none',
        paddingBottom: '0px',
        marginBottom: '0px',
      },
    },
    '& .smooth-dnd-container *': {
      '& >article': {
        '&:hover': {
          boxShadow: ' 3px 4px 13px rgba(0, 131, 230, 0.5)',
        },
      },
    },
    '& .smooth-dnd-container.horizontal': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      flexWrap: 'wrap',
    },
    '& .smooth-dnd-container.vertical': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      minHeight: '55vh',
    },
  },
}));

export default ConsultaProposta;
