import gql from 'graphql-tag';

export const GET_COMERCIAL_LOGADO = gql`
  query GetComercialLogado {
    comercial: comercialLogado {
      id
      email
      celular
      fotoPerfil
      nomeFantasia
      nomeContato
      telefone
      nomeRazao
      cpfCnpj
      nomeRazaoResponsavel
      cpfCnpjResponsavel
      role
      comercialResponsavel {
        id
        nomeRazao
        cpfCnpj
        telefone
        email
        endereco {
          logradouro
          numero
          complemento
          bairro
          cep
          cidade {
            nome
            uf
          }
        }
      }
      endereco {
        cidade {
          latitude
          longitude
        }
      }
      usuario {
        authorities {
          nome
        }
      }
    }
  }
`;

export const TOTALIZADORES_CAMPANHAS = gql`
  query GetTotaisCampanhas {
    totalCampanhasFinalizadasDoComercial
    totalCampanhasAtivasDoComercial
  }
`;

export const FIND_TOTAIS_PROPOSTAS_BY_COMERCIAL = gql`
  query findTotaisPropostasByComercial($anuncianteId: UUID, $data: LocalDate) {
    totais: findTotaisPropostasByComercial(
      anuncianteId: $anuncianteId
      data: $data
    ) {
      situacao
      quantidadeOrcamentos
    }
  }
`;

export const FIND_TOTAIS_PROPOSTAS_BY_PROSPECTOR = gql`
  query findTotaisPropostasByProspector(
    $anuncianteId: UUID
    $comercialId: UUID
    $data: LocalDate
  ) {
    totais: findTotaisPropostasByProspector(
      anuncianteId: $anuncianteId
      comercialId: $comercialId
      data: $data
    ) {
      situacao
      quantidadeOrcamentos
    }
  }
`;

export const FIND_ALL_CIDADE = gql`
  query findAllCidade($pageable: PageableDTOInput, $searchDTO: SearchDTOInput) {
    findAllCidade(pageable: $pageable, searchDTO: $searchDTO) {
      last
      content {
        value: id
        label: nomeEUf
        id
        nome
        uf
        latitude
        longitude
        kmPadrao
        kmMedio
        raioPadrao
        impactadosKm
        mediaCorridas
        estado {
          kmPadrao
          raioPadrao
        }
      }
    }
  }
`;

export const FIND_ALL_ESTADOS = gql`
  query findAllEstado($pageable: PageableDTOInput, $searchDTO: SearchDTOInput) {
    estados: findAllEstado(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        id
        nome
        uf
      }
    }
  }
`;

export const FIND_ALL_MODELO_ANUNCIO = gql`
  query findAllModeloAnuncio(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    modelos: findAllModeloAnuncio(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        value: id
        label: nome
        id
        nome
        descricao
        valorMensalMotorista
        valorInstaladorPorInstalacao
        valorInstaladorPorRemocao
        quantidadeKmMes
        quantidadeMinimaVeiculos
        quantidadeMinimaMeses
        valorMotorista
        valorVenda
        percentualImpactos
        diasPeriodo
        tabelaEspecial
        tabelaUm
        tabelaDois
        tabelaTres
        interno
        ativo
      }
    }
  }
`;

export const FIND_COMERCIAL = gql`
  query findAllComercial(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    comerciais: findAllComercial(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      numberOfElements
      pageable {
        pageNumber
      }
      content {
        value: id
        label: nomeRazao
        id
        nomeRazao
        nomeFantasia
      }
    }
  }
`;

export const FIND_ANUNCIANTES_DEMONSTRACAO = gql`
  query findAllPainelDemonstracao {
    anunciantes: findAllPainelDemonstracao {
      id
      nomeFantasia
      situacao
    }
  }
`;

export const FIND_ANUNCIANTE_BY_COMERCIAL = gql`
  query findAnuncianteByComercial(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    anunciantes: findAnuncianteByComercial(
      pageable: $pageable
      searchDTO: $searchDTO
    ) {
      last
      totalElements
      numberOfElements
      pageable {
        pageNumber
      }
      content {
        value: id
        label: nomeFantasia
        id
        nomeRazao
        nomeFantasia
        cnpj
        fotoPerfil
        dataCadastro
        cadastroCompleto
        tipo
        endereco {
          cidade {
            nome
            uf
          }
          bairro
          complemento
          logradouro
          numero
          latitude
          longitude
        }
        comercial {
          id
          nomeFantasia
          nomeRazao
        }
        agencia {
          id
          nomeFantasia
        }
        executivo {
          id
          nome
        }
        segmentoAtuacao {
          descricao
        }
      }
    }
  }
`;

export const PRIMEIRO_ANUNCIO_DA_CAMPANHA = gql`
  query PrimeiroAnuncioDaCampanha($campanha: CampanhaInput) {
    primeiroAnuncioDaCampanha(campanha: $campanha) {
      id
      dataInstalacao
      dataInicioVeiculacao
      dataConfirmacaoInstalacao
      imagensDoVeiculo {
        id
        imagemOdometro
        imagemDiagonalDireitaFrontal
        imagemDiagonalEsquerdaTraseira
        imagemLateralEsquerda
        imagemLateralDireita
        imagemFrente
        imagemTraseira
        situacao
      }
    }
  }
`;

export const FIND_ULTIMAS_LOCALIZACOES_DA_CAMPANHA = gql`
  query findUltimasLocalizacoesDaCampanha($campanha: CampanhaInput) {
    findUltimasLocalizacoesDaCampanha(campanha: $campanha) {
      data
      latitude
      longitude
      monitoramento {
        motorista {
          nome
        }
      }
    }
  }
`;

export const CALCULATE_CAMPANHA = gql`
  query CalculateCampanha(
    $campanha: CampanhaInput
    $parcelamento: ParcelamentoDTOInput
  ) {
    calculateCampanha(campanha: $campanha, parcelamento: $parcelamento) {
      valorPorVeiculo
      quantidadeVeiculos
      quantidadeMeses
      quantidadeKmMotoristaMes
      valor
      valorVeiculacao
      valorVeiculacaoMensal
      kmTotal
      movimentacaoFinanceiras {
        valor
        dataPrevisaoPgto
        parcelaTipo
        tipo
      }
    }
  }
`;

export const FIND_CAMPANHA_BY_COMERCIAL = gql`
  query findCampanhaByComercial(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    campanhas: findCampanhaByComercial(
      pageable: $pageable
      searchDTO: $searchDTO
    ) {
      last
      totalElements
      numberOfElements
      pageable {
        pageNumber
      }
      content {
        id
        numero
        titulo
        situacao
        quantidadeVeiculos
        quantidadeMeses
        dataCompra
        dataInicioVeiculacao
        diasPeriodo
        anunciante {
          id
          nomeRazao
          nomeFantasia
        }
        comercial {
          nomeRazao
          nomeFantasia
          endereco {
            cidade {
              nome
              uf
              latitude
              longitude
            }
          }
        }
        cidades {
          modeloAnuncio {
            nome
          }
          cidade {
            nome
            uf
            latitude
            longitude
          }
        }
      }
    }
  }
`;

export const FIND_BY_ID_CAMPANHA = gql`
  query findByIdCampanha($id: UUID) {
    findByIdCampanha(id: $id) {
      id
      titulo
      numero
      dataInicioVeiculacao
      diasPeriodo
      bannerCampanha
      valorMensalRepasseComercial
      situacao
      faturarPor
      quantidadeMeses
      quantidadeVeiculos
      anunciante {
        id
        nomeRazao
        nomeFantasia
        value: id
        label: nomeFantasia
        comercial {
          id
          nomeFantasia
          nomeRazao
        }
      }
      comercial {
        id
        nomeFantasia
        nomeRazao
        value: id
        label: nomeRazao
      }
      cidades {
        id
        instalador {
          id
          email
          nomeFantasia
          value: id
          label: nomeFantasia
        }
        modeloAnuncio {
          value: id
          label: nome
          id
          nome
          valorVenda
          valorMotorista
          valorInstaladorPorInstalacao
          diasPeriodo
          tabelaEspecial
          tabelaUm
          tabelaDois
          tabelaTres
        }
        cidade {
          id
          nome
          uf
          nomeEUf
          estado {
            id
            nome
          }
          latitude
          longitude
          value: id
          label: nomeEUf
        }
        quantidadeVeiculos
        quantidadeKmMotoristaMes
        valorPorVeiculo
        desconto
        valorBrutoVeiculo
        instalacaoPorVeiculo
        valorMensalMotorista
        valorMotorista
        raioCidade
      }
      valor
      valorVeiculacaoMensal
      valorVeiculacao
      valorReajuste
      kmTotal
      formaDePagamento
      formaDePagamentoProducao
      repasseAgencia
      repasseTerceiros
      impostos
      faturarProducao
      pedidoInsercao {
        id
        nome
      }
      pedidoInsercaoProducao {
        id
        nome
      }
      observacoesRelatorios
      faturamentoProprioProducao
      movimentacaoFinanceiras {
        id
        valor
        formaPagamento
        dataPrevisaoPgto
        tipo
        parcelaTipo
        dataEmitirNota
        notaEmitida
        anunciante {
          id
        }
      }
      usuario {
        id
        nome
      }
      mensagemReprovacao
    }
  }
`;

export const FIND_EXTRATO_REPASSE_DO_COMERCIAL = gql`
  query findExtratoRepasseDoComercial(
    $pageable: PageableDTOInput
    $searchDTO: SearchMovimentacoesDTOInput
  ) {
    extrato: findExtratoRepasseDoComercial(
      pageable: $pageable
      searchDTO: $searchDTO
    ) {
      last
      totalElements
      content {
        id
        dataInicioPeriodo
        dataFimPeriodo
        dataPgto
        dataLcto
        dataAprovacao
        dataPrevisaoPgto
        valor
        valorPago
        valorReferencia
        origemMovimentacaoFinanceira
        tipo
        movimentacaoOrigem
        parcelaTipo
        situacao
        anuncio {
          motorista {
            nome
          }
          campanha {
            cidade {
              nomeEUf
            }
          }
        }
        motorista {
          nome
          cpf
          dadoBancario {
            chavePix
          }
        }
        instalador {
          nome
        }
        comercial {
          nomeRazao
          nomeFantasia
          role
        }
        licencaRecebimento {
          nomeRazao
          nomeFantasia
          role
        }
        anunciante {
          nomeRazao
          nomeFantasia
        }
        campanha {
          numero
          titulo
          valorVeiculacaoLiquido
          parcelasVeiculacao
          repasseTerceiros
          repasseAgencia
          quantidadeMeses
          valorVeiculacao
          anunciante {
            nomeRazao
            nomeFantasia
          }
          comercial {
            role
          }
          cidades {
            modeloAnuncio {
              nome
            }
            cidade {
              id
              nomeEUf
            }
          }
        }
      }
    }
  }
`;

export const FIND_ALL_VEICULOS_DA_CAMPANHA = gql`
  query findAllVeiculosDaCampanha(
    $campanha: CampanhaInput
    $pageable: PageableDTOInput
  ) {
    findAllVeiculosDaCampanha(campanha: $campanha, pageable: $pageable) {
      last
      totalElements
      content {
        id
        nome
        anuncioAtivo {
          situacao
          dataInstalacao
          dataInicioVeiculacao
          dataRemocao
        }
        veiculoAtual {
          anoFabricacao
          anoModelo
          modelo {
            nome
            marca {
              nome
            }
          }
        }
      }
    }
  }
`;

export const VERIFICAR_SENHA = gql`
  query verificarSenha($senha: String) {
    validaSenhaAdministradorComercial(senha: $senha) {
      id
    }
  }
`;

export const FIND_CONFIGURACOES = gql`
  query findConfiguracoes($keys: [String]) {
    configuracoes(keys: $keys) {
      key
      value
    }
  }
`;

export const FIND_ALL_ANUNCIOS = gql`
  query findAllAnuncios(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    anuncios: findAllAnuncios(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        id
        campanhaTitulo
        situacao
        motorista {
          nome
          fotoPerfil
        }
        localizacaoAtual {
          latitude
          longitude
        }
        veiculo {
          anoFabricacao
          anoModelo
          modelo {
            nome
            marca {
              nome
            }
          }
        }
      }
    }
  }
`;

export const FIND_BY_ID_ANUNCIANTE = gql`
  query findByIdAnunciante($id: UUID) {
    anunciante: findByIdAnunciante(id: $id) {
      celular
      cnpj
      inscricaoEstadual
      dataCadastro
      email
      usuario {
        email
      }
      endereco {
        bairro
        logradouro
        complemento
        cep
        cidade {
          id
          nome
          uf
          value: id
          label: nomeEUf
        }
        numero
      }
      fotoPerfil
      id
      nomeContato
      nomeFantasia
      nomeRazao
      site
      marketingNome
      marketingEmail
      marketingTelefone
      financeiroNome
      financeiroEmail
      financeiroTelefone
      marketingNomeAdicional
      marketingTelefoneAdicional
      marketingEmailAdicional
      financeiroNomeAdicional
      financeiroTelefoneAdicional
      financeiroEmailAdicional
      emailUsuario
      telefone
      tipo
      comercial {
        id
        nomeRazao
        nomeFantasia
        value: id
        label: nomeRazao
      }
      agencia {
        id
        nomeRazao
        nomeFantasia
        value: id
        label: nomeFantasia
      }
      executivo {
        id
        nome
        value: id
        label: nome
      }
      segmentoAtuacao {
        id
        descricao
        value: id
        label: descricao
      }
      cadastroCompleto
    }
  }
`;

export const FIND_ALL_PROPOSTA_BY_COMERCIAL = gql`
  query findAllPropostaByComercial(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    propostas: findAllPropostaByComercial(
      pageable: $pageable
      searchDTO: $searchDTO
    ) {
      last
      totalElements
      content {
        id
        titulo
        situacao
        dataCadastro
        dataAlteracao
        dataRetorno
        reagendamentos
        comercial {
          nomeRazao
        }
        anunciante {
          nomeFantasia
          fotoPerfil
        }
        orcamentos {
          id
          situacao
          quantidadeVeiculos
          reprovadoMotivo
        }
      }
    }
  }
`;

export const FIND_ALL_PROPOSTA_BY_PROSPECTOR = gql`
  query findAllPropostaByProspector(
    $pageable: PageableDTOInput
    $searchDTO: SearchPropostasDTOInput
  ) {
    propostas: findAllPropostaByProspector(
      pageable: $pageable
      searchDTO: $searchDTO
    ) {
      last
      totalElements
      content {
        id
        titulo
        situacao
        dataCadastro
        dataAlteracao
        dataRetorno
        reagendamentos
        anunciante {
          nomeFantasia
          fotoPerfil
        }
        orcamentos {
          id
        }
      }
    }
  }
`;

export const FIND_PROPOSTA_BY_ID = gql`
  query findByIdProposta($id: UUID) {
    proposta: findByIdProposta(id: $id) {
      id
      titulo
      situacao
      dataCadastro
      dataAlteracao
      dataRetorno
      comercial {
        nomeFantasia
        nomeRazao
        endereco {
          cidade {
            nomeEUf
          }
        }
      }
      anunciante {
        value: id
        label: nomeFantasia
        celular
        cnpj
        inscricaoEstadual
        dataCadastro
        email
        emailUsuario
        usuario {
          email
        }
        endereco {
          bairro
          logradouro
          complemento
          cep
          cidade {
            id
            nome
            uf
            value: id
            label: nomeEUf
          }
          numero
        }
        fotoPerfil
        id
        nomeContato
        nomeFantasia
        nomeRazao
        site
        marketingNome
        marketingEmail
        marketingTelefone
        financeiroNome
        financeiroEmail
        financeiroTelefone
        emailUsuario
        telefone
        cadastroCompleto
      }
      observacao
      reprovadoMotivo
      eventos {
        id
        evento
        data
      }
      orcamentos {
        id
      }
    }
  }
`;

export const FIND_ALL_ORCAMENTOS_PROPOSTA = gql`
  query findAllOrcamentosDaProposta($proposta: PropostaInput) {
    orcamentos: findAllOrcamentosDaProposta(proposta: $proposta) {
      id
      titulo
      anunciante {
        value: id
        label: nomeFantasia
        id
        nomeFantasia
        cadastroCompleto
      }
      diasPeriodo
      dataCadastro
      dataAlteracao
      quantidadeMeses
      comissaoAgencia
      valorTotalVeiculacaoMensal
      producaoPorVeiculo
      valorTotalCampanha
      situacao
      dataRetorno
      observacoes
      reprovadoMotivo
      dataInicio
      dataAprovadoAdmin
      comissaoTerceiros
      parcelasVeiculacao
      dataVencimentoVeiculacao
      dataEmissaoNotaVeiculacao
      faturarProducao
      parcelasProducao
      dataVencimentoProducao
      dataEmissaoNotaProducao
      formaPagamentoVeiculacao
      faturarPor
      formaPagamentoProducao
      piVeiculacao {
        id
        nome
      }
      piProducao {
        id
        nome
      }
      itens {
        id
        cidade {
          id
          nome
          uf
          nomeEUf
          kmPadrao
          kmMedio
          impactadosKm
          mediaCorridas
          value: id
          label: nomeEUf
        }
        modeloAnuncio {
          id
          nome
          value: id
          label: nome
          valorInstaladorPorInstalacao
          valorVenda
          percentualImpactos
          diasPeriodo
          tabelaEspecial
          tabelaUm
          tabelaDois
          tabelaTres
          interno
        }
        producaoPorVeiculo
        valorMensalPorVeiculo
        quantidadeVeiculos
        valorDescontoPorVeiculo
        valorTotalCidade
        motoristas
        mediaKmRodado
      }
      eventos {
        id
        data
        evento
        automatico
      }
    }
  }
`;

export const FIND_BY_ID_MOVIMENTACAO_FINANCEIRA = gql`
  query findByIdMovimentacaoFinanceira($id: UUID) {
    findByIdMovimentacaoFinanceira(id: $id) {
      id
      dataInicioPeriodo
      dataFimPeriodo
      dataPgto
      dataLcto
      dataPrevisaoPgto
      reciboAssinadoData
      valor
      valorPago
      origemMovimentacaoFinanceira
      tipo
      formaPagamento
      valorBonusDiarioPerido
      valorBonusExtraPerido
      valorBonusKmPerido
      valorBonusMensalPerido
      kmRodadoPeriodo
      quantidadeBonusDiarioPeriodo
      obs
      codigoComprovante
      dataLctoPgto
      movimentacaoOrigem
      situacao
      parcelaTipo
      usuarioBaixa {
        nome
      }
      anuncio {
        motorista {
          nome
        }
        veiculoAnoFabricacao
        veiculoAnoModelo
        veiculoModelo
        veiculoPlaca
      }
      motorista {
        nome
        dadoBancario {
          agencia
          banco {
            codigoBanco
            nomeBanco
          }
          conta
          chavePix
          cpfCnpjFavorecido
          nomeFavorecido
          tipoContaBancaria
        }
      }
      instalador {
        nome
      }
      comercial {
        nomeRazao
        nomeFantasia
        porcentagemValorRepasse
        porcentagemImportacao
        dadoBancario {
          agencia
          banco {
            codigoBanco
            nomeBanco
          }
          conta
          chavePix
          cpfCnpjFavorecido
          nomeFavorecido
          tipoContaBancaria
        }
      }
      licencaRecebimento {
        nomeRazao
        nomeFantasia
        porcentagemValorRepasse
        porcentagemImportacao
        dadoBancario {
          agencia
          banco {
            codigoBanco
            nomeBanco
          }
          conta
          chavePix
          cpfCnpjFavorecido
          nomeFavorecido
          tipoContaBancaria
        }
      }
      anunciante {
        nomeRazao
        cnpj
        comercial {
          nomeRazao
          nomeFantasia
        }
      }
      campanhaCidade {
        modeloAnuncio {
          id
          nome
        }
      }
      campanha {
        numero
        titulo
        valorVeiculacao
        repasseAgencia
        repasseTerceiros
        quantidadeMeses
        movimentacaoFinanceiras {
          parcelaTipo
          tipo
        }
        anunciante {
          cnpj
          nomeRazao
          nomeFantasia
        }
        comercial {
          nomeRazao
          nomeFantasia
          nomeRazaoResponsavel
        }
      }
    }
  }
`;

export const FIND_ALL_AGENCIA = gql`
  query findAllAgenciaDoComercial(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    agencias: findAllAgenciaDoComercial(
      pageable: $pageable
      searchDTO: $searchDTO
    ) {
      last
      totalElements
      content {
        id
        nomeRazao
        nomeFantasia
        cnpj
        ativo
        cidade {
          id
          nome
          uf
        }
        comercial {
          id
          nomeRazao
          value: id
          label: nomeRazao
        }
        marketingTelefone
        marketingEmail
        marketingNome
      }
    }
  }
`;
export const FIND_ALL_AGENCIA_CADASTRADAS = gql`
  query findAllAgencia(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    agencias: findAllAgencia(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        id
        nomeRazao
        nomeFantasia
        cnpj
        ativo
        cidade {
          id
          nome
          uf
        }
        comercial {
          id
          nomeRazao
          value: id
          label: nomeRazao
        }
        marketingTelefone
        marketingEmail
        marketingNome
      }
    }
  }
`;

export const FIND_BY_ID_AGENCIA = gql`
  query findByIdAgencia($id: UUID) {
    agencia: findByIdAgencia(id: $id) {
      id
      fotoPerfil
      nomeFantasia
      nomeRazao
      cnpj
      email
      inscricaoEstadual
      dataCadastro
      bairro
      logradouro
      complemento
      cep
      cidade {
        id
        nome
        uf
      }
      numero
      site
      marketingNome
      marketingEmail
      marketingTelefone
      financeiroNome
      financeiroEmail
      financeiroTelefone
      telefone
      comercial {
        id
        nomeRazao
        nomeFantasia
        value: id
        label: nomeRazao
      }
      ativo
    }
  }
`;

export const FIND_ALL_EXECUTIVO = gql`
  query findAllComercialExecutivoByComercial(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    executivos: findAllComercialExecutivoByComercial(
      pageable: $pageable
      searchDTO: $searchDTO
    ) {
      last
      totalElements
      content {
        id
        nome
        email
        telefone
        cpf
        ativo
      }
    }
  }
`;

export const FIND_BY_ID_EXECUTIVO = gql`
  query findByIdComercialExecutivo($id: UUID) {
    executivo: findByIdComercialExecutivo(id: $id) {
      id
      nome
      email
      telefone
      cpf
      ativo
    }
  }
`;
export const FIND_ALL_EVENTOS_AGENDA = gql`
  query findAllEventosAgenda(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    findAllEventosAgenda(pageable: $pageable, searchDTO: $searchDTO) {
      totalPages
      totalElements
      pageable {
        pageNumber
      }
      size
      content {
        id
        descricao
        observacao
        id
        titulo
        data
        situacao
        orcamento {
          id
        }
        proposta {
          id
        }
      }
    }
  }
`;

export const FIND_ALL_EVENTOS_AGENDA_LIST = gql`
  query findAllEventosAgendaList($searchDTO: SearchDTOInput) {
    findAllEventosAgendaList(searchDTO: $searchDTO) {
      orcamento {
        id
      }
      descricao
      id
      titulo
      data
      situacao
    }
  }
`;
export const FIND_BY_ID_EVENTO_AGENDA = gql`
  query findByIdEventoAgenda($id: UUID) {
    findByIdEventoAgenda(id: $id) {
      content {
        anunciante {
          id
          nomeFantasia
        }
        descricao
        id
        orcamento
        titulo
        data
      }
    }
  }
`;

export const FIND_ALL_SEGMENTOS = gql`
  query findAllSegmentoAtuacao(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    segmentos: findAllSegmentoAtuacao(
      pageable: $pageable
      searchDTO: $searchDTO
    ) {
      last
      totalElements
      content {
        id
        descricao
        value: id
        label: descricao
      }
    }
  }
`;

export const EXISTS_ANUNCIANTE_BY_CNPJ = gql`
  query existsAnuncianteByCnpj($anunciante: AnuncianteInput) {
    isDuplicado: existsAnuncianteByCnpj(anunciante: $anunciante)
  }
`;

export const COUNT_PROPOSTAS_ABERTAS_ANUNCIANTE = gql`
  query countPropostaAbertasByAnunciante($anunciante: AnuncianteInput) {
    propostas: countPropostaAbertasByAnunciante(anunciante: $anunciante) {
      id
    }
  }
`;
export const FIND_ALL_ARQUIVOS = gql`
  query findAllArquivos(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    findAllArquivos(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        titulo
        id
        descricao
        dataAlteracao
        urlArquivo
        arquivo {
          id
          nome
        }
      }
    }
  }
`;
export const FIND_BY_ID_ARQUIVO = gql`
  query findByIdArquivo($id: UUID) {
    arquivo: findByIdArquivo(id: $id) {
      id
      descricao
      titulo
      dataAlteracao
      arquivo {
        id
        nome
      }
    }
  }
`;

export const FIND_ALL_DURACAO_PERIODO = gql`
  query findAllDuracaoPeriodoModelo {
    dias: findAllDuracaoPeriodoModelo
  }
`;

export const FIND_ALL_MOTORISTAS_CIDADE = gql`
  query findAllMotoristasDisponiveisPorCidade($search: SearchDTOInput) {
    motoristas: findAllMotoristasDisponiveisPorCidade(search: $search) {
      id
      nome
      motoristas
      motoristasDisponiveis
    }
  }
`;

export const FIND_ALL_ORCAMENTOS_APROVAR = gql`
  query findAllOrcamentosAprovar(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    pedidos: findAllOrcamentosAprovar(
      pageable: $pageable
      searchDTO: $searchDTO
    ) {
      last
      totalElements
      content {
        id
        titulo
        totalVeiculos
        dataInicio
        quantidadeMeses
        anunciante {
          nomeRazao
          nomeFantasia
        }
        comercial {
          nomeRazao
          nomeFantasia
        }
        itens {
          id
        }
        modeloAnuncio {
          nome
          diasPeriodo
        }
        proposta {
          id
          titulo
        }
      }
    }
  }
`;

export const FIND_BY_ID_ORCAMENTO = gql`
  query findByIdOrcamento($id: UUID) {
    orcamento: findByIdOrcamento(id: $id) {
      id
      proposta {
        id
        titulo
      }
      anunciante {
        value: id
        label: nomeFantasia
        id
        nomeFantasia
        cadastroCompleto
        comercial {
          id
          nomeRazao
          nomeFantasia
        }
      }
      comercial {
        id
        nomeRazao
        nomeFantasia
      }
      diasPeriodo
      valorMensalPorVeiculo
      dataCadastro
      dataAlteracao
      quantidadeMeses
      comissaoAgencia
      valorTotalVeiculacaoMensal
      valorTotalProducao
      valorTotalCampanha
      valorReajuste
      situacao
      dataRetorno
      observacoes
      reprovadoMotivo
      totalVeiculos
      dataInicio
      comissaoTerceiros
      parcelasVeiculacao
      dataVencimentoVeiculacao
      dataEmissaoNotaVeiculacao
      faturarProducao
      parcelasProducao
      dataVencimentoProducao
      dataEmissaoNotaProducao
      formaPagamentoVeiculacao
      faturarPor
      formaPagamentoProducao
      piVeiculacao {
        id
        nome
      }
      piProducao {
        id
        nome
      }
      itens {
        id
        cidade {
          id
          nome
          uf
          nomeEUf
          kmPadrao
          kmMedio
          impactadosKm
          mediaCorridas
          value: id
          label: nomeEUf
        }
        modeloAnuncio {
          id
          nome
          value: id
          label: nome
          valorInstaladorPorInstalacao
          valorVenda
          percentualImpactos
          diasPeriodo
          tabelaEspecial
          tabelaUm
          tabelaDois
          tabelaTres
        }
        producaoPorVeiculo
        valorMensalPorVeiculo
        quantidadeVeiculos
        valorDescontoPorVeiculo
        valorTotalCidade
        motoristas
        mediaKmRodado
      }
    }
  }
`;

export const FIND_ALL_COMERCIAL = gql`
  query findAllComercial(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    findAllComercial(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        id
        nomeRazao
        nomeFantasia
        cpfCnpj
        ativo
        endereco {
          cidade {
            id
            nome
            uf
            latitude
            longitude
          }
        }
        estadosAtuacao {
          id
          uf
        }
      }
    }
  }
`;

export const FIND_BY_ID_COMERCIAL = gql`
  query findByIdComercial($id: UUID) {
    comercial: findByIdComercial(id: $id) {
      celular
      cpfCnpj
      email
      endereco {
        bairro
        logradouro
        complemento
        cep
        cidade {
          id
          nome
          uf
          nomeEUf
        }
        numero
      }
      dadoBancario {
        agencia
        banco {
          id
          codigoBanco
          nomeBanco
          codigoENome
        }
        cidade {
          id
          nome
          uf
          nomeEUf
        }
        conta
        chavePix
        cpfCnpjFavorecido
        nomeFavorecido
        tipoContaBancaria
      }
      fotoPerfil
      id
      nomeContato
      nomeFantasia
      nomeRazao
      porcentagemValorRepasse
      telefone
      ativo
      usuario {
        authorities {
          nome
        }
      }
      arquivos {
        id
        titulo
        dataAlteracao
        arquivo {
          id
          nome
        }
      }
      estadosAtuacao {
        id
        uf
        value: id
        label: uf
      }
    }
  }
`;

export const FIND_ALL_BANCOS = gql`
  query findAllBanco($pageable: PageableDTOInput, $searchDTO: SearchDTOInput) {
    bancos: findAllBanco(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        id
        nomeBanco
        codigoBanco
        codigoENome
      }
    }
  }
`;

export const FIND_ALL_CIDADES_CAMPANHA = gql`
  query findAllCidadesByCampanha(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    cidades: findAllCidadesByCampanha(
      searchDTO: $searchDTO
      pageable: $pageable
    ) {
      totalElements
      content {
        id
        titulo
        situacao
        quantidadeVeiculos
        cidade {
          id
          nomeEUf
          latitude
          longitude
        }
        modeloAnuncio {
          nome
        }
        anuncios {
          id
          situacao
        }
        campanha {
          id
        }
        anunciante {
          id
          nomeFantasia
        }
      }
    }
  }
`;

export const FIND_ALL_ANUNCIOS_BY_CAMPANHA_CIDADE = gql`
  query findAllAnunciosByCampanhaCidade($campanhaCidade: CampanhaCidadeInput) {
    anuncios: findAllAnunciosByCampanhaCidade(campanhaCidade: $campanhaCidade) {
      id
      dataInstalacao
      dataInicioVeiculacao
      kmTotalPorMes
      quantidadeMeses
      kmRodado
      kmTotal
      kmRodadoDia
      tempoRodado
      veiculo {
        id
        anoFabricacao
        anoModelo
        placa
        modelo {
          nome
        }
      }
      motorista {
        id
        nome
        fotoPerfil
        veiculoAtual {
          id
          anoModelo
          anoFabricacao
          placa
          modelo {
            nome
            marca {
              nome
            }
          }
        }
      }
      localizacaoAtual {
        latitude
        longitude
        data
      }
      modeloAnuncio {
        frente
        laterais
        traseira
        interno
      }
    }
  }
`;

export const FIND_ALL_IMAGENS_APROVADAS_ANUNCIO = gql`
  query findAllImagensVeiculoAprovadasByAnuncio(
    $anuncio: AnuncioInput
    $dataInicio: LocalDate
    $dataFim: LocalDate
  ) {
    imagensAnuncio: findAllImagensVeiculoAprovadasByAnuncio(
      anuncio: $anuncio
      dataInicio: $dataInicio
      dataFim: $dataFim
    ) {
      id
      imagemLateralEsquerda
      imagemLateralDireita
      imagemFrente
      imagemTraseira
      imagemInterna
      imagemDiagonalDireitaFrontal
      imagemDiagonalEsquerdaTraseira
      imagemOdometro
      valorOdometro
      tipo
      data
      motorista {
        id
      }
    }
    kmRodado: quilometroRodadoPeriodoAnuncio(
      anuncio: $anuncio
      dataInicio: $dataInicio
      dataFim: $dataFim
    )
  }
`;

export const TOTAL_CAMPANHA_POR_CIDADE = gql`
  query totalCampanhaPorCidadeDoComercial {
    totais: totalCampanhaPorCidadeDoComercial {
      id
      nome
      uf
      latitude
      longitude
      campanhas
      situacao
    }
  }
`;

export const FIND_BY_ID_MODELO_ANUNCIO = gql`
  query findByIdModeloAnuncio($id: UUID) {
    modelo: findByIdModeloAnuncio(id: $id) {
      id
      nome
      descricao
      valorMotorista
      valorBonusInstalacao
      valorMarkup
      valorVenda
      valorMensalBonusDiario
      valorMensalBonusExtra
      valorMensalBonusKmRodado
      valorMensalBonusMensal
      quantidadeKmBonusDiario
      quantidadeKmMes
      quantidadeMaximaDiasBonusDiario
      quantidadeMinimaMeses
      quantidadeMinimaVeiculos
      valorInstaladorPorInstalacao
      valorInstaladorPorRemocao
      valorMensalRepasseKMMoney
      tempoInstalacaoMinutos
      ativo
      frente
      laterais
      lateraisRecorte
      traseira
      interno
      interativo
      percentualImpactos
      diasPeriodo
      tabelaEspecial
      tabelaUm
      tabelaDois
      tabelaTres
    }
  }
`;
