import React from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {Instagram, Mail, Phone} from '@mui/icons-material';
import moment from 'moment';
import {useSelector} from 'react-redux';
import {ReactComponent as KmMidiaLogo} from '../../assets/images/km-midia-logo.svg';
import Number from '../../utils/number';
import string from '../../utils/string';

const Divisor = require('../../assets/images/divisor-relatorio.png');

const comercialKm = {
  nomeRazao: 'KM MONEY SISTEMAS LTDA',
  cpfCnpj: '33793168000150',
  telefone: '44999150120',
  email: 'atendimento@kmmidia.com.br',
  emailContato: 'atendimento@kmmidia.com.br',
  instagram: '@kmmidiaooh',
  endereco: {
    logradouro: 'Av. das Nações Unidas',
    numero: '12901',
    complemento: 'Torre Norte - 25º andar - Sala 160',
    bairro: 'Brooklin Paulista',
    cep: '04578-910',
    cidade: {
      nome: 'São Paulo',
      uf: 'SP'
    }
  }
};

const ImpressaoPI = ({proposta, orcamento, faturamento}) => {
  const {role, comercialResponsavel} = useSelector((store) => store.Auth);
  const comercial = role === 'COMERCIAL' ? comercialKm : comercialResponsavel;
  const {itens = []} = orcamento;
  const valorTotalMensal = itens
    .map((item) => item.quantidadeVeiculos * Number.stringToFloat(item.valorMensalVeiculo))
    .reduce((total, item) => total + item, 0);
  const valorTotalDesconto = itens
    .map((item) => item.quantidadeVeiculos * Number.stringToFloat(item.valorDescontoPorVeiculo))
    .reduce((total, item) => total + item, 0);
  const valorTotalMensalComDesconto = itens
    .map((item) => Number.stringToFloat(item.valorTotalCidade))
    .reduce((total, item) => total + item, 0);
  const valorTotalLiquido = orcamento.valorTotalVeiculacaoMensal * orcamento.quantidadeMeses - Number.stringToFloat(orcamento.comissaoAgencia);
  const veiculacao = Array.from(Array(Number.safeParseInt(faturamento.parcelas)).keys());
  const parcelaVeiculacao = faturamento.faturarPor?.value === 'VALOR_LIQUIDO' ? valorTotalLiquido / faturamento.parcelas : (orcamento.valorTotalVeiculacaoMensal * orcamento.quantidadeMeses) / faturamento.parcelas;
  const producao = Array.from(Array(Number.safeParseInt(faturamento.parcelasProducao)).keys());
  const parcelaProducao = orcamento.valorTotalProducao / faturamento.parcelasProducao;

  const calcularDataParcela = (data) => {
    const diaSemana = data.isoWeekday();

    if ([6, 7].includes(diaSemana))
      return data.isoWeekday(5).format('DD/MM/YYYY');

    return data.format('DD/MM/YYYY');
  }

  return (
    <Grid item xs={12} sx={styles.reportContainer}>
      <Grid item xs={12} sx={styles.reportHeader}>
        <Grid item xs={10}>
          <Typography sx={styles.titleReport} style={{fontWeight: '700'}}>
            Documento de autorização de veiculação
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <KmMidiaLogo style={styles.titleLogo} />
        </Grid>
      </Grid>
      <Grid sx={styles.contratada}>
        <Typography sx={styles.subTitle}><b>I. Contratada:</b></Typography>
        <Grid container direction='row'>
          <Typography sx={styles.textContainer} style={{width: '55%'}}>
            Razão social: <b>{comercial.nomeRazao}</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '20%'}}>
            CNPJ: <b>{string.formatCpfCnpj(comercial.cpfCnpj)}</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '25%'}}>
            Inscrição estadual:
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '45%'}}>
            Endereço: <b>{comercial.endereco.logradouro}</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '15%'}}>
            Nº: <b>{comercial.endereco.numero}</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '40%'}}>
            Bairro: <b>{comercial.endereco.bairro}</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '85%'}}>
            Comp: <b>{comercial.endereco.complemento}</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '15%'}}>
            Cep: <b>{string.formatCep(comercial.endereco.cep)}</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '35%'}}>
            Cidade: <b>{comercial.endereco.cidade.nome}</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '15%'}}>
            Estado: <b>{comercial.endereco.cidade.uf}</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '20%'}}>
            Telefone: <b>{string.formatTelefone(comercial.telefone)}</b>
          </Typography>
          <Typography sx={styles.textContainer} style={{width: '30%'}}>
            E-mail: <b>{comercial.email}</b>
          </Typography>
        </Grid>
      </Grid>
      <Grid sx={styles.contratada}>
        <Typography sx={styles.subTitle}><b>II. Contratante:</b></Typography>
        <Grid container direction='row'>
          <Typography sx={styles.textContainer} style={{width: '50%'}}>Razão Social: <b>{proposta?.anunciante?.nomeRazao}</b></Typography>
          <Typography sx={styles.textContainer} style={{width: '50%'}}>Nome fantasia: <b>{proposta?.anunciante?.nomeFantasia}</b></Typography>
          <Typography sx={styles.textContainer} style={{width: '50%'}}>CNPJ: <b>{string.formatCpfCnpj(proposta?.anunciante?.cnpj)}</b></Typography>
          <Typography sx={styles.textContainer} style={{width: '50%'}}>Inscrição Estadual:<b>{proposta?.anunciante?.inscricaoEstadual}</b></Typography>
          <Typography sx={styles.textContainer} style={{width: '50%'}}>Endereço: <b>{proposta?.anunciante?.endereco?.logradouro}</b></Typography>
          <Typography sx={styles.textContainer} style={{width: '20%'}}>Nº: <b>{proposta?.anunciante?.endereco?.numero}</b></Typography>
          <Typography sx={styles.textContainer} style={{width: '30%'}}>Comp: <b>{proposta?.anunciante?.endereco?.complemento}</b></Typography>
          <Typography sx={styles.textContainer} style={{width: '30%'}}>Bairro: <b>{proposta?.anunciante?.endereco?.bairro}</b></Typography>
          <Typography sx={styles.textContainer} style={{width: '20%'}}>Cep: <b>{string.formatCep(proposta?.anunciante?.endereco?.cep)}</b></Typography>
          <Typography sx={styles.textContainer} style={{width: '50%'}}>Cidade: <b>{proposta?.anunciante?.endereco?.cidade?.nome}</b></Typography>
          <Typography sx={styles.textContainer} style={{width: '20%'}}>Estado: <b>{proposta?.anunciante?.endereco?.cidade?.uf}</b></Typography>
          <Typography sx={styles.textContainer} style={{width: '25%'}}>Telefone fixo: <b>{string.formatTelefone(proposta?.anunciante?.telefone)}</b></Typography>
          <Typography sx={styles.textContainer} style={{width: '25%'}}>Telefone cel: <b>{string.formatTelefone(proposta?.anunciante?.celular)}</b></Typography>
          <Typography sx={styles.textContainer} style={{width: '30%'}}>E-mail: <b>{proposta?.anunciante?.email}</b></Typography>
          <Typography sx={styles.textContainer} style={{width: '40%'}}>Resp. Marketing: <b>{proposta?.anunciante?.marketingNome}</b></Typography>
          <Typography sx={styles.textContainer} style={{width: '30%'}}>Fone: <b>{string.formatTelefone(proposta?.anunciante?.marketingTelefone)}</b></Typography>
          <Typography sx={styles.textContainer} style={{width: '30%'}}>E-mail: <b>{proposta?.anunciante?.marketingEmail}</b></Typography>
        </Grid>
      </Grid>
      <Grid sx={styles.contratada}>
        <Typography sx={styles.subTitle}><b>III. Dados do contrato:</b></Typography>
        <Grid container direction='row'>
          <Grid container item xs={3} direction='column' sx={styles.textContainer}>
            <Typography>Data da contratação:</Typography>
            <Typography><b>{moment().format('DD/MM/YYYY')}</b></Typography>
          </Grid>
          <Grid container item xs={3} direction='column' sx={styles.textContainer}>
            <Typography>Prazo do contrato:</Typography>
            <Typography><b>{`${orcamento.quantidadeMeses} período${orcamento.quantidadeMeses === 1 ? '' : 's'} de ${orcamento.diasPeriodo?.value} dias`}</b></Typography>
          </Grid>
          <Grid container item xs={2} direction='column' sx={styles.textContainer}>
            <Typography>Quantidade de veículos:</Typography>
            <Typography><b>{orcamento.totalVeiculos}</b></Typography>
          </Grid>
          <Grid container item xs={4} direction='column' sx={styles.textContainer}>
            <Typography>Data de veiculação:</Typography>
            <Typography>
              <b>
                Início: {moment(faturamento.dataInicioVeiculacao).format('DD/MM/YYYY')} - Término: {
                  moment(faturamento.dataInicioVeiculacao).add(orcamento.quantidadeMeses * orcamento.diasPeriodo?.value, 'day').format('DD/MM/YYYY')}
              </b>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid sx={styles.cidades}>
        <Typography sx={styles.subTitle}><b>IV. Detalhes da veiculação (Por período):</b></Typography>
        <Grid container direction='row'>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={styles.textContainer}>Cidades de veiculação</TableCell>
                <TableCell sx={styles.textContainer}>Tipo de anúncio</TableCell>
                <TableCell sx={styles.textContainer}>Nº de veículos</TableCell>
                <TableCell sx={styles.textContainer}>Valor por veículo</TableCell>
                <TableCell sx={styles.textContainer}>Valor total</TableCell>
                <TableCell sx={styles.textContainer}>Desconto</TableCell>
                <TableCell sx={styles.textContainer}>Valor total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {itens.map((item, index) => (
                <TableRow key={index}>
                  <TableCell sx={styles.textContainer}>{item.cidade.nomeEUf}</TableCell>
                  <TableCell sx={styles.textContainer}>{item.modeloAnuncio?.nome}</TableCell>
                  <TableCell sx={styles.textContainer}>{item.quantidadeVeiculos}</TableCell>
                  <TableCell sx={styles.textContainer}>{Number.currencyFormat(item.valorMensalVeiculo)}</TableCell>
                  <TableCell sx={styles.textContainer}>{Number.currencyFormat(item.quantidadeVeiculos * Number.stringToFloat(item.valorMensalVeiculo))}</TableCell>
                  <TableCell sx={styles.textContainer}>{Number.currencyFormat(item.quantidadeVeiculos * Number.stringToFloat(item.valorDescontoPorVeiculo))}</TableCell>
                  <TableCell sx={styles.textContainer}>{Number.currencyFormat(item.valorTotalCidade)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell sx={styles.textFooter}><b>Total</b></TableCell>
                <TableCell sx={styles.textFooter} />
                <TableCell sx={styles.textFooter}><b>{orcamento.totalVeiculos}</b></TableCell>
                <TableCell sx={styles.textFooter} />
                <TableCell sx={styles.textFooter}><b>{Number.currencyFormat(valorTotalMensal)}</b></TableCell>
                <TableCell sx={styles.textFooter}><b>{Number.currencyFormat(valorTotalDesconto)}</b></TableCell>
                <TableCell sx={styles.textFooter}><b>{Number.currencyFormat(valorTotalMensalComDesconto)}</b></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{whiteSpace: 'nowrap', borderBottom: 'none'}}>
                  <Typography sx={styles.footerText} style={{height: 40}}>
                    <br />
                  </Typography>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Grid>
      </Grid>
      <Grid sx={styles.contratada}>
        <Typography sx={styles.subTitle}><b>V. Valor do contrato:</b></Typography>
        <Grid container direction='row'>
          <Typography sx={styles.textContainer} style={{width: '75%'}}><b>Valor total do contrato (BRUTO)</b></Typography>
          <Typography sx={styles.textContainer} style={{width: '25%'}}><b>{Number.currencyFormat(orcamento.valorTotalVeiculacaoMensal * orcamento.quantidadeMeses)}</b></Typography>

          <Typography sx={styles.textContainer} style={{width: '75%'}}><b>Comissão de agência (COMISSÃO/AG)</b></Typography>
          <Typography sx={styles.textContainer} style={{width: '25%'}}><b>{Number.currencyFormat(orcamento.comissaoAgencia)}</b></Typography>

          <Typography sx={styles.textContainer} style={{width: '75%'}}><b>Valor total do contrato (LÍQUIDO)</b></Typography>
          <Typography sx={styles.textContainer} style={{width: '25%'}}><b>{Number.currencyFormat(valorTotalLiquido)}</b></Typography>
        </Grid>
      </Grid>
      <Grid sx={styles.contratada}>
        <Typography sx={styles.subTitle}><b>VI. Forma de pagamento:</b></Typography>
        <Grid container direction='row'>
          {veiculacao.map((parcela) => (
            <React.Fragment key={parcela}>
              <Typography sx={styles.textContainer} style={{width: '20%'}}><b>{parcela + 1}ª Parcela:</b>{Number.currencyFormat(parcelaVeiculacao)}</Typography>
              <Typography sx={styles.textContainer} style={{width: '30%'}}><b>Forma:</b>{faturamento.formaDePagamento?.label}</Typography>
              <Typography sx={styles.textContainer} style={{width: '30%'}}><b>Vencimento:</b>{calcularDataParcela(moment(faturamento.vencimentoParcelas).add(parcela, 'M'))}</Typography>
              <Typography sx={styles.textContainer} style={{width: '20%'}}><b>VEICULAÇÃO</b></Typography>
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
      <Grid container sx={styles.assinatura}>
        <Grid container item xs={12}>
          <Typography align='center'>
            <b>Faz parte do presente instrumento o contrato de locação de espaço destinado a publicidade em veículos (anexo), cujo a contratante declara ter tido acesso, no ato da assinatura.</b>
          </Typography>
        </Grid>
        <Grid container item xs={6}>
          <Typography align='center' className="borderTop">
            <b>{comercial.nomeRazao} (CONTRATADA)</b>
          </Typography>
        </Grid>
        <Grid container item xs={6}>
          <Typography align='center' className="borderTop">
            <b>(CONTRATANTE)</b>
          </Typography>
        </Grid>
      </Grid>
      {faturamento.faturarProducao && (
        <Grid style={{pageBreakBefore: 'always'}}>
          <Grid item xs={12} sx={styles.reportHeader}>
            <Grid item xs={10}>
              <Typography sx={styles.titleReport} style={{fontWeight: '700'}}>
                Documento de autorização de produção
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <KmMidiaLogo style={styles.titleLogo} />
            </Grid>
          </Grid>
          <Grid sx={styles.contratada}>
            <Typography sx={styles.subTitle}><b>I. Detalhes da produção:</b></Typography>
            <Grid container direction='row'>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={styles.textContainer}>Cidade</TableCell>
                    <TableCell sx={styles.textContainer}>Tipo da plotagem</TableCell>
                    <TableCell sx={styles.textContainer}>Nº de veículos</TableCell>
                    <TableCell sx={styles.textContainer}>Valor por veículo</TableCell>
                    <TableCell sx={styles.textContainer}>Valor total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {itens.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell sx={styles.textContainer}>{item.cidade?.nomeEUf}</TableCell>
                      <TableCell sx={styles.textContainer}>{item.modeloAnuncio?.nome}</TableCell>
                      <TableCell sx={styles.textContainer}>{item.quantidadeVeiculos}</TableCell>
                      <TableCell sx={styles.textContainer}>{Number.currencyFormat(item.producaoPorVeiculo)}</TableCell>
                      <TableCell sx={styles.textContainer}>{Number.currencyFormat(item.quantidadeVeiculos * Number.stringToFloat(item.producaoPorVeiculo))}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell sx={styles.textFooter}><b>Total</b></TableCell>
                    <TableCell sx={styles.textFooter} />
                    <TableCell sx={styles.textFooter}><b>{orcamento.totalVeiculos}</b></TableCell>
                    <TableCell sx={styles.textFooter} />
                    <TableCell sx={styles.textFooter}><b>{Number.currencyFormat(orcamento.valorTotalProducao)}</b></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{whiteSpace: 'nowrap', borderBottom: 'none'}}>
                      <Typography sx={styles.footerText} style={{height: 40}}>
                        <br />
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </Grid>
          </Grid>
          <Grid sx={styles.contratada}>
            <Typography sx={styles.subTitle}><b>II. Forma de pagamento:</b></Typography>
            <Grid container direction='row'>
              {faturamento.faturarProducao && producao.map(parcela => (
                <React.Fragment key={parcela}>
                  <Typography sx={styles.textContainer} style={{width: '20%'}}><b>{parcela + 1}ª Parcela:</b>{Number.currencyFormat(parcelaProducao)}</Typography>
                  <Typography sx={styles.textContainer} style={{width: '30%'}}><b>Forma:</b>{faturamento.formaDePagamentoProducao?.label}</Typography>
                  <Typography sx={styles.textContainer} style={{width: '30%'}}><b>Vencimento:</b>{calcularDataParcela(moment(faturamento.vencimentoParcelasProducao).add(parcela, 'M'))}</Typography>
                  <Typography sx={styles.textContainer} style={{width: '20%'}}><b>PRODUÇÃO</b></Typography>
                </React.Fragment>
              ))}
            </Grid>
          </Grid>
          <Grid container sx={styles.assinatura}>
            <Grid container item xs={12}>
              <Typography align='center'>
                <b>Faz parte do presente instrumento o contrato de locação de espaço destinado a publicidade em veículos (anexo), cujo a contratante declara ter tido acesso, no ato da assinatura.</b>
              </Typography>
            </Grid>
            <Grid container item xs={6}>
              <Typography align='center' className="borderTop">
                <b>{comercial.nomeRazao} (CONTRATADA)</b>
              </Typography>
            </Grid>
            <Grid container item xs={6}>
              <Typography align='center' className="borderTop">
                <b>(CONTRATANTE)</b>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        className={`page-footer`}
        sx={styles.footer}
        style={{display: 'none'}}
      >
        <img alt={'divisor'} style={{width: '100%', margin: '5px 0'}} src={Divisor} />
        <Grid container direction='column' justifyContent='space-between'>
          <Typography sx={styles.footerSubText}>
            {`${comercial.endereco.logradouro}, nº ${comercial.endereco.numero} - 
            ${comercial.endereco.complemento} - ${comercial.endereco.cidade.nome} - ${comercial.endereco.cidade.uf} - Brasil`}
          </Typography>
          <Grid container item direction='row' justifyContent='center'>
            <Typography sx={styles.footerSubText}>
              <Phone sx={styles.footerIcon} />{string.formatTelefone(comercial.telefone)}
            </Typography>
            {comercial.emailContato && (
              <Typography sx={styles.footerSubText} style={{margin: '0 10px'}}>
                <Mail sx={styles.footerIcon} /> {comercial.emailContato}
              </Typography>
            )}
            {comercial.instagram && (
              <Typography sx={styles.footerSubText}>
                <Instagram sx={styles.footerIcon} /> {comercial.instagram}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = {
  reportContainer: {
    backgroundColor: '#fff',
    borderRadius: '4px',
    padding: '0 10px',
  },
  titleReport: {
    marginLeft: '15px',
    fontSize: 18,
    color: '#031663',
  },
  titleLogo: {
    width: '100%',
    height: '30px'
  },
  reportHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  footer: {
    margin: '0px 10px',
    position: 'fixed',
    width: '100%',
    top: '1140px',
  },
  footerIcon: {
    height: '15px',
    width: '15px',
    marginRight: '6px',
  },
  footerSubText: {
    display: 'flex',
    fontSize: 11,
    paddingRight: '10px',
    textAlign: 'center',
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center'
  },
  contratoPagina: {
    breakInside: 'avoid',
    paddingTop: '15px',

    '& p': {
      fontSize: 10
    }
  },
  contratada: {
    padding: '35px 0',
    margin: '-35px 0',
    breakInside: 'avoid',
    '& *': {
      fontSize: '10px !important'
    }
  },
  cidades: {
    marginTop: '30px',
    '& *': {
      fontSize: '10px !important'
    }
  },
  assinatura: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'space-around',
    marginTop: '20px',
    breakInside: 'avoid',
    '& *': {
      fontSize: '10px !important'
    },
    '& p': {
      padding: '5px',
      margin: '0 30px',
      width: '100%',
      height: '80px'
    },
    '& .borderTop': {
      borderTop: '1px solid black',
      marginTop: '40px'
    }
  },
  subTitle: {
    marginBottom: '5px'
  },
  textContainer: {
    padding: '0 5px',
    border: '1px solid black'
  },
  textFooter: {
    color: '#000',
    backgroundColor: '#d3d3d3',
    padding: '0 5px',
    border: '1px solid black'
  },
  paragrafo: {
    margin: '15px 0'
  }
};

export default ImpressaoPI;
