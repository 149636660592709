import {useMutation, useQuery} from '@apollo/client';
import {
  Add,
  Close,
  Delete,
  FileCopyOutlined,
  RequestQuoteOutlined,
  Save,
} from '@mui/icons-material';
import {Box, Grid, Typography} from '@mui/material';
import {Field, Formik} from 'formik';
import React, {useEffect, useRef, useState} from 'react';
import ReactToPrint from 'react-to-print';
import * as Yup from 'yup';
import ImpressaoOrcamento from '../../../components/impressao/impressao-orcamento';
import InputV2 from '../../../components/input-v2/mui-input-v2';
import {
  ButtonComponent,
  RoundTooltipButton,
} from '../../../components/mui-button';
import Select from '../../../components/select/mui-select';
import Textarea from '../../../components/textarea/mui-textarea';
import {toastNotification} from '../../../components/toastify';
import {CRIAR_ORCAMENTO} from '../../../graphql/mutation';
import {FIND_ALL_DURACAO_PERIODO} from '../../../graphql/queries';
import Number from '../../../utils/number';
import {SelectCidades} from '../../selects/cidades';
import {SelectModeloAnuncio} from '../../selects/modelo-anuncio';
import CadastroClienteSimplificadoModal from '../cadastro-proposta/cadastro-cliente-simplificado-modal';

const pageStyle = `
  @page { 
    size: A4 landscape; 
    margin: 5mm 10mm 5mm 10mm; 
  }
  @media print { 
    html, body { 
      height: initial !important; 
      overflow: initial !important;
      width: 1135px;
    }
  }
  @media all {
    .page-footer {
      display: block !important;
    }
  }
`;

const CadastroOrcamento = ({proposta, onClose, onSave}) => {
  const componentRef = useRef();
  const printButtonRef = useRef();

  const [anunciante, setAnunciante] = useState(proposta?.anunciante);
  const [orcamento, setOrcamento] = useState({
    quantidadeMeses: 1,
  });
  const [keyCount, setKeyCount] = useState(1);
  const [orcamentoItens, setOrcamentoItens] = useState([{id: 0}]);
  const [openCadastroCliente, setOpenCadastroCliente] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [fieldErro, setFieldErro] = useState([]);

  const periodosQuery = useQuery(FIND_ALL_DURACAO_PERIODO);
  const [createOrcamento] = useMutation(CRIAR_ORCAMENTO);

  useEffect(() => {
    calcFields();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orcamentoItens, orcamento.quantidadeMeses]);

  useEffect(() => {
    handleChangeFieldPercent(
      'comissaoAgencia',
      'repasseAgencia',
      orcamento.repasseAgencia,
      orcamento.valorTotalVeiculacao,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orcamento.valorTotalVeiculacao]);

  const getKey = () => {
    const key = keyCount + 1;
    setKeyCount(key);

    return key;
  };

  const formularioOk = () => {
    const erros = [];

    if (!orcamento?.quantidadeMeses) erros.push('quantidadeMeses');
    if (!orcamento?.comissaoAgencia) erros.push('comissaoAgencia');
    if (!orcamento?.diasPeriodo) erros.push('diasPeriodo');

    if (orcamentoItens.some((item) => !item.modeloAnuncio?.id))
      erros.push('modeloAnuncio');
    if (orcamentoItens.some((item) => !item.cidade?.id)) erros.push('cidade');
    if (orcamentoItens.some((item) => !item.quantidadeVeiculos))
      erros.push('quantidadeVeiculos');
    if (orcamentoItens.some((item) => !item.valorDescontoPorVeiculo))
      erros.push('valorDescontoPorVeiculo');
    if (orcamentoItens.some((item) => !item.producaoPorVeiculo))
      erros.push('producaoPorVeiculo');

    setFieldErro(erros);
    return erros.length;
  };

  const handleSubmitDadosCampanha = () => {
    if (formularioOk() > 0) return;

    setSubmitting(true);

    createOrcamento({
      variables: {
        orcamento: {
          titulo: orcamento.titulo,
          proposta: {
            id: proposta.id,
          },
          anunciante: {
            id: anunciante.id,
            nomeFantasia: anunciante.nomeFantasia,
          },
          quantidadeMeses: orcamento.quantidadeMeses,
          comissaoAgencia: Number.stringToFloat(orcamento.comissaoAgencia || 0),
          valorTotalVeiculacaoMensal: Number.stringToFloat(
            orcamento.valorTotalVeiculacaoMensal || 0,
          ),
          valorTotalCampanha: Number.stringToFloat(
            orcamento.valorTotalCampanha || 0,
          ),
          diasPeriodo: orcamento.diasPeriodo?.value,
          valorTotalProducao: Number.stringToFloat(
            orcamento.valorTotalProducao,
          ),
          itens: orcamentoItens
            .filter((item) => item.cidade?.id && item.quantidadeVeiculos > 0)
            .map((item) => ({
              modeloAnuncio: {
                id: item.modeloAnuncio?.id,
              },
              cidade: {
                id: item.cidade.id,
              },
              producaoPorVeiculo: Number.stringToFloat(
                item.producaoPorVeiculo || 0,
              ),
              valorMensalPorVeiculo: Number.stringToFloat(
                item.modeloAnuncio?.valorVenda,
              ),
              quantidadeVeiculos: item.quantidadeVeiculos,
              valorDescontoPorVeiculo: Number.stringToFloat(
                item.valorDescontoPorVeiculo,
              ),
              valorTotalCidade: Number.stringToFloat(item.valorTotalCidade),
              mediaKmRodado: Number.stringToFloat(item.mediaKmRodado),
            })),
          observacoes: orcamento.observacoes,
        },
      },
    })
      .then(() => {
        setSubmitting(false);
        toastNotification({
          message: 'Proposta cadastrada com sucesso',
          type: 'success',
        });
        onSave && onSave();
        printButtonRef.current.click();
      })
      .catch((error) => {
        setSubmitting(false);
        toastNotification({message: error.message, type: 'error'});
      });
  };

  const handleChangeFieldValor = (
    field,
    percentField,
    valor,
    valorReferencia,
  ) => {
    setOrcamento({
      ...orcamento,
      [field]: valor,
      [percentField]: Number.currencyFormat(
        Number.regraDeTresPorcentagem(valor, valorReferencia),
      ),
    });
  };

  const handleChangeFieldPercent = (
    field,
    percentField,
    valor,
    valorReferencia,
  ) => {
    const valorCalculo = Number.regraDeTresValor(valorReferencia, valor);
    setOrcamento({
      ...orcamento,
      [percentField]: valor,
      [field]: Number.currencyFormat(valorCalculo),
    });

    return valorCalculo;
  };

  const calcFields = () => {
    const totalVeiculos = orcamentoItens
      .map((item) => parseInt(item.quantidadeVeiculos || 0))
      .reduce((total, item) => total + item, 0);
    const valorTotalVeiculacaoMensal = orcamentoItens
      .map((item) => Number.stringToFloat(item.valorTotalCidade || 0))
      .reduce((total, item) => total + item, 0);
    const valorTotalVeiculacao =
      valorTotalVeiculacaoMensal * parseInt(orcamento.quantidadeMeses || 0);
    const valorTotalProducao = orcamentoItens
      .map(
        (item) =>
          Number.stringToFloat(item.producaoPorVeiculo || 0) *
          item.quantidadeVeiculos,
      )
      .reduce((total, item) => total + item, 0);
    const valorTotalCampanha =
      valorTotalVeiculacao +
      (valorTotalProducao +
        valorTotalProducao * 0.1 * orcamento.quantidadeMeses);

    setOrcamento({
      ...orcamento,
      totalVeiculos,
      valorTotalVeiculacaoMensal,
      valorTotalVeiculacao,
      valorTotalProducao,
      valorTotalCampanha,
    });
  };

  const handleClickClose = () => {
    setOrcamento({quantidadeMeses: 1});
    onClose();
  };

  const handleDuplicateItem = (itemIndex) => {
    const newItem = orcamentoItens[itemIndex];
    orcamentoItens.splice(itemIndex + 1, 0, {
      ...newItem,
      id: getKey(),
      cidade: null,
    });
  };

  const handleDeleteItem = (itemIndex) => {
    orcamentoItens.splice(itemIndex, 1);
    setOrcamentoItens([...orcamentoItens]);
  };

  const handleNewItem = () => {
    orcamentoItens.unshift({id: getKey()});
  };

  return (
    <Grid sx={styles.container}>
      <Grid className="styled-scroll" sx={styles.form}>
        <ReactToPrint
          pageStyle={pageStyle}
          documentTitle={`KM Mídia - ${
            orcamento?.anunciante?.nomeFantasia || 'Anunciante'
          }`}
          onAfterPrint={handleClickClose}
          trigger={() => (
            <button ref={printButtonRef} style={{display: 'none'}} />
          )}
          content={() => componentRef.current}
        />
        <Grid sx={styles.content} container>
          <Formik
            validationSchema={OrcamentoScheme}
            enableReinitialize
            initialValues={{
              ...orcamento,
              anunciante,
            }}>
            {() => (
              <Box component="form">
                <Grid container spacing={2} style={{width: '100%'}}>
                  <Grid item xs={12} md={4}>
                    <InputV2
                      label="Cliente"
                      name="cliente"
                      value={proposta.anunciante.nomeFantasia}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputV2
                      label="Titulo"
                      name="titulo"
                      value={proposta.titulo || ''}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <InputV2
                      label="Períodos"
                      name="quantidadeMeses"
                      value={orcamento.quantidadeMeses}
                      onChange={({target}) =>
                        setOrcamento({
                          ...orcamento,
                          quantidadeMeses: target.value,
                        })
                      }
                      error={fieldErro.includes('quantidadeMeses')}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Select
                      name="diasPeriodo"
                      label="Duração período"
                      value={orcamento.diasPeriodo}
                      onChange={(e) =>
                        setOrcamento({...orcamento, diasPeriodo: e})
                      }
                      options={periodosQuery?.data?.dias?.map((p) => ({
                        value: p,
                        label: p,
                      }))}
                      error={
                        !orcamento.diasPeriodo ||
                        fieldErro.includes('diasPeriodo')
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="space-between"
                  sx={{marginTop: '8px'}}>
                  <Typography sx={styles.headerText}>Cidades</Typography>
                  <Add
                    style={{color: '#007BE1', cursor: 'pointer'}}
                    onClick={handleNewItem}
                  />
                </Grid>
                <Grid item xs={12} sx={styles.cidadesContainer}>
                  {orcamentoItens.map((item, index) => (
                    <OrcamentoItem
                      key={item.id}
                      item={item}
                      diasPeriodo={orcamento?.diasPeriodo?.value}
                      setValue={(e) => {
                        orcamentoItens[index] = e;
                        setOrcamentoItens([...orcamentoItens]);
                      }}
                      onDuplicate={() => handleDuplicateItem(index)}
                      onDelete={() => handleDeleteItem(index)}
                      fieldErro={fieldErro}
                    />
                  ))}
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="flex-end"
                  spacing={2}>
                  <Grid container item xs={12} md={10}>
                    <Grid
                      container
                      item
                      direction="row"
                      spacing={2}
                      justifyContent="flex-end">
                      <Grid item xs={12} md={4}>
                        <InputV2
                          label="Total de carros"
                          name="totalVeiculos"
                          value={orcamento.totalVeiculos || 0}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <InputV2
                          label="Veiculação / mês"
                          name="valorTotalVeiculacaoMensal"
                          value={Number.currencyFormat(
                            orcamento.valorTotalVeiculacaoMensal || 0,
                          )}
                          mask="moeda"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <InputV2
                          label="Total veiculação"
                          name="valorTotalVeiculacao"
                          value={Number.currencyFormat(
                            orcamento.valorTotalVeiculacao || 0,
                          )}
                          mask="moeda"
                          disabled
                        />
                      </Grid>
                      <Grid container item xs={12} md={4} spacing={0}>
                        <Grid item xs={5}>
                          <InputV2
                            name="repasseAgencia"
                            mask="porcento"
                            label="Agência"
                            value={orcamento.repasseAgencia}
                            onChange={({target}) =>
                              handleChangeFieldPercent(
                                'comissaoAgencia',
                                'repasseAgencia',
                                target.value,
                                orcamento.valorTotalVeiculacao,
                              )
                            }
                            error={fieldErro.includes('comissaoAgencia')}
                          />
                        </Grid>
                        <Grid item xs={7}>
                          <InputV2
                            label="Valor"
                            name="comissaoAgencia"
                            mask="moeda"
                            value={orcamento.comissaoAgencia}
                            onChange={({target}) =>
                              handleChangeFieldValor(
                                'comissaoAgencia',
                                'repasseAgencia',
                                target.value,
                                orcamento.valorTotalVeiculacao,
                              )
                            }
                            error={fieldErro.includes('comissaoAgencia')}
                          />
                        </Grid>
                      </Grid>
                      <Grid container item xs={12} md={4} spacing={0}>
                        <Grid item xs={7}>
                          <InputV2
                            label="Total produção"
                            name="valorTotalProducao"
                            mask="moeda"
                            value={Number.currencyFormat(
                              orcamento.valorTotalProducao || 0,
                            )}
                            style={{
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                            }}
                            sx={styles.inputPercent}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <InputV2
                            label="Reserva técnica"
                            name="reservaTecnica"
                            mask="moeda"
                            value={Number.currencyFormat(
                              Number.safeParseFloat(
                                orcamento.valorTotalProducao,
                              ) *
                                0.1 *
                                orcamento.quantidadeMeses,
                            )}
                            style={{
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                            }}
                            sx={styles.inputValue}
                            disabled
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <InputV2
                          label="Valor total da campanha"
                          name="valorTotalCampanha"
                          mask="moeda"
                          value={Number.currencyFormat(
                            orcamento.valorTotalCampanha || 0,
                          )}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Textarea
                      name="observacoes"
                      label="Observações"
                      value={orcamento.observacoes || ''}
                      onChange={({target}) =>
                        setOrcamento({...orcamento, observacoes: target.value})
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </Formik>
          <CadastroClienteSimplificadoModal
            isOpen={openCadastroCliente}
            onClose={() => setOpenCadastroCliente(false)}
            onSave={(anunciante) => {
              setAnunciante(anunciante);
              setOpenCadastroCliente(false);
            }}
          />
        </Grid>
        <div style={{overflow: 'hidden', height: '0px'}}>
          <Grid ref={componentRef} style={{background: '#fff'}}>
            <ImpressaoOrcamento
              orcamento={{...orcamento, anunciante}}
              orcamentoItens={orcamentoItens}
            />
          </Grid>
        </div>
      </Grid>
      <Grid sx={styles.footer}>
        <ButtonComponent
          id="button-cancel-empty"
          value="Cancelar"
          type="button"
          icon={<Close />}
          onClick={handleClickClose}
          sx={styles.buttons}
          disabled={isSubmitting}
        />
        <ButtonComponent
          id="button"
          type="button"
          loading={isSubmitting}
          icon={<Save />}
          value={'Salvar'}
          onClick={handleSubmitDadosCampanha}
          sx={styles.buttons}
          disabled={isSubmitting}
        />
      </Grid>
    </Grid>
  );
};

const OrcamentoScheme = Yup.object().shape({
  anunciante: Yup.object()
    .shape({value: Yup.string()})
    .nullable()
    .required('Campo obrigatório'),
  diasPeriodo: Yup.object()
    .shape({value: Yup.string()})
    .nullable()
    .required('Campo obrigatório'),
  quantidadeMeses: Yup.string().required('Campo obrigatório'),
});

const OrcamentoItem = ({
  setValue,
  item = {},
  diasPeriodo,
  onDuplicate,
  onDelete,
  fieldErro,
}) => {
  const [openTabela, setOpenTabela] = useState(false);
  const [hasModelo, setHasModelo] = useState(!!item.modeloAnuncio?.id);
  const [orcamentoItem, setOrcamentoItem] = useState(item);

  const handleChangeFieldValor = (
    field,
    percentField,
    valor,
    valorReferencia,
  ) => {
    const valorFinalVeiculo =
      (orcamentoItem?.modeloAnuncio.valorVenda || 0) -
      Number.stringToFloat(valor);
    setState({
      ...orcamentoItem,
      [field]: valor,
      [percentField]: Number.currencyFormat(
        Number.regraDeTresPorcentagem(valor, valorReferencia),
      ),
      valorFinalVeiculo: Number.currencyFormat(valorFinalVeiculo),
      valorTotalCidade: Number.currencyFormat(
        valorFinalVeiculo * parseInt(orcamentoItem.quantidadeVeiculos || 0),
      ),
    });
  };

  const handleChangeFieldPercent = (
    field,
    percentField,
    valor,
    valorReferencia,
  ) => {
    const valorDesconto = Number.regraDeTresValor(valorReferencia, valor);
    const valorFinalVeiculo =
      (orcamentoItem?.modeloAnuncio?.valorVenda || 0) - valorDesconto;
    setState({
      ...orcamentoItem,
      [percentField]: valor,
      [field]: Number.currencyFormat(valorDesconto),
      valorFinalVeiculo: Number.currencyFormat(valorFinalVeiculo),
      valorTotalCidade: Number.currencyFormat(
        valorFinalVeiculo * parseInt(orcamentoItem.quantidadeVeiculos || 0),
      ),
    });

    return valorDesconto;
  };

  useEffect(() => {
    setHasModelo(!!orcamentoItem?.modeloAnuncio?.id);
    if (!orcamentoItem?.modeloAnuncio) return;

    calcValues();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    orcamentoItem.modeloAnuncio,
    orcamentoItem.quantidadeVeiculos,
    orcamentoItem.producaoPorVeiculo,
    orcamentoItem.cidade,
  ]);

  useEffect(() => {
    if (!orcamentoItem.cidade?.id) return;

    setState({
      ...orcamentoItem,
      mediaKmRodado:
        orcamentoItem.cidade?.kmMedio || orcamentoItem.cidade?.kmPadrao || 0,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orcamentoItem.cidade]);

  useEffect(() => {
    if (!diasPeriodo || item.modeloAnuncio?.diasPeriodo === diasPeriodo) return;

    setState((i) => ({...i, modeloAnuncio: undefined}));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diasPeriodo]);

  const calcValues = () => {
    const valorDescontoPorVeiculo =
      orcamentoItem.desconto &&
      Number.regraDeTresValor(
        orcamentoItem?.modeloAnuncio.valorVenda,
        orcamentoItem.desconto,
        true,
      );

    const valorComDesconto =
      (orcamentoItem?.modeloAnuncio.valorVenda || 0) -
      Number.stringToFloat(valorDescontoPorVeiculo);

    setState({
      ...orcamentoItem,
      valorDescontoPorVeiculo,
      valorMensalVeiculo: Number.currencyFormat(
        orcamentoItem?.modeloAnuncio.valorVenda,
      ),
      valorFinalVeiculo: Number.currencyFormat(valorComDesconto),
      valorTotalCidade: Number.currencyFormat(
        valorComDesconto * parseInt(orcamentoItem.quantidadeVeiculos || 0),
      ),
    });
  };

  const setState = (state) => {
    setOrcamentoItem({...item, ...state});
    setValue({...item, ...state});
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...orcamentoItem,
      }}>
      <Grid sx={styles.itemRowContainer}>
        <Grid container item direction="row" rowSpacing={2} columnSpacing={1}>
          <Grid item xs={3}>
            <Field
              name="cidade"
              component={SelectCidades}
              onChange={(cidade) => setState({...orcamentoItem, cidade})}
              error={fieldErro.includes('cidade') && !orcamentoItem.cidade?.id}
              disabled={!diasPeriodo}
            />
          </Grid>
          <Grid item xs={4}>
            <Grid
              item
              style={{display: 'flex', width: 'inherit', position: 'relative'}}>
              <Field
                name="modeloAnuncio"
                component={({...props}) => (
                  <SelectModeloAnuncio
                    searchDTO={{dias: diasPeriodo || null}}
                    endAdornment={
                      <RoundTooltipButton
                        type="button"
                        title={'Tabela de preços'}
                        sx={styles.novoClienteButton}
                        onClick={() =>
                          setOpenTabela(
                            (open) => !open && orcamentoItem?.modeloAnuncio?.id,
                          )
                        }
                        icon={<RequestQuoteOutlined />}
                        disabled={!diasPeriodo}
                      />
                    }
                    {...props}
                  />
                )}
                onChange={(modeloAnuncio) => {
                  setState({
                    ...orcamentoItem,
                    modeloAnuncio,
                    valorMensalVeiculo: Number.currencyFormat(
                      modeloAnuncio.valorVenda,
                    ),
                    producaoPorVeiculo: Number.currencyFormat(
                      modeloAnuncio.valorInstaladorPorInstalacao,
                    ),
                  });
                }}
                error={fieldErro.includes('modeloAnuncio')}
                disabled={!diasPeriodo}
              />
              {openTabela && (
                <Grid sx={styles.tabelaModal}>
                  <Grid>
                    <Typography sx={styles.tabelaModalTitle}>
                      Produção
                    </Typography>
                    <Typography
                      sx={styles.tabelaModalDesc}
                      style={{color: '#0000'}}>
                      {' '}
                      -{' '}
                    </Typography>
                    <Typography sx={styles.tabelaModalValue}>
                      {`${Number.currencyFormat(
                        orcamentoItem?.modeloAnuncio
                          .valorInstaladorPorInstalacao,
                      )}`}
                    </Typography>
                  </Grid>
                  <Grid sx={styles.tabelaModalContainer} />
                  <Grid>
                    <Typography sx={styles.tabelaModalTitle}>
                      Veiculação
                    </Typography>
                    <Typography
                      sx={styles.tabelaModalDesc}
                      style={{color: '#0000'}}>
                      {' '}
                      -{' '}
                    </Typography>
                    <Typography sx={styles.tabelaModalValue}>
                      {`${Number.currencyFormat(
                        orcamentoItem?.modeloAnuncio.valorVenda,
                      )}`}
                    </Typography>
                  </Grid>
                  <Grid sx={styles.tabelaModalContainer} />
                  <Grid>
                    <Typography sx={styles.tabelaModalTitle}>
                      Veiculação
                    </Typography>
                    <Typography sx={styles.tabelaModalDesc}>
                      (Valor recomendado)
                    </Typography>
                    <Typography sx={styles.tabelaModalValue}>
                      {`${
                        Number.format(
                          orcamentoItem?.modeloAnuncio.tabelaTres,
                        ) || 0
                      } % - ${Number.currencyFormat(
                        ((100 - orcamentoItem?.modeloAnuncio.tabelaTres) /
                          100) *
                          orcamentoItem?.modeloAnuncio.valorVenda,
                      )}`}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <InputV2
              label="Qtd. carros"
              name="quantidadeVeiculos"
              value={orcamentoItem.quantidadeVeiculos || ''}
              onChange={({target}) =>
                setOrcamentoItem({
                  ...orcamentoItem,
                  quantidadeVeiculos: target.value,
                })
              }
              disabled={!hasModelo}
              error={
                fieldErro.includes('quantidadeVeiculos') &&
                !orcamentoItem.quantidadeVeiculos
              }
            />
          </Grid>
          <Grid item xs={3}>
            <InputV2
              label="Valor mensal por veículo"
              name="valorMensalVeiculo"
              mask="moeda"
              value={orcamentoItem.valorMensalVeiculo || 0}
              disabled
            />
          </Grid>
          <Grid container item xs={4} spacing={0}>
            <Grid item xs={5}>
              <InputV2
                label="Desconto"
                name="desconto"
                mask="porcento"
                value={orcamentoItem.desconto}
                onChange={({target}) =>
                  handleChangeFieldPercent(
                    'valorDescontoPorVeiculo',
                    'desconto',
                    target.value,
                    orcamentoItem?.modeloAnuncio?.valorVenda,
                  )
                }
                disabled={!hasModelo}
                error={
                  fieldErro.includes('valorDescontoPorVeiculo') &&
                  !orcamentoItem.valorDescontoPorVeiculo
                }
              />
            </Grid>
            <Grid item xs={7}>
              <InputV2
                label="Valor"
                name="valorDescontoPorVeiculo"
                mask="moeda"
                value={orcamentoItem.valorDescontoPorVeiculo}
                onChange={({target}) =>
                  handleChangeFieldValor(
                    'valorDescontoPorVeiculo',
                    'desconto',
                    target.value,
                    orcamentoItem?.modeloAnuncio?.valorVenda,
                  )
                }
                disabled={!hasModelo}
                error={
                  fieldErro.includes('valorDescontoPorVeiculo') &&
                  !orcamentoItem.valorDescontoPorVeiculo
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <InputV2
              label="Valor final por veículo"
              name="valorFinalVeiculo"
              mask="moeda"
              value={orcamentoItem.valorFinalVeiculo || 0}
              disabled
            />
          </Grid>
          <Grid item xs={2}>
            <InputV2
              label="Valor total"
              name="valorTotalCidade"
              mask="moeda"
              value={orcamentoItem.valorTotalCidade || 0}
              disabled
            />
          </Grid>
          <Grid item xs={2}>
            <InputV2
              label="Produção por carro"
              name="producaoPorVeiculo"
              mask="moeda"
              value={orcamentoItem.producaoPorVeiculo}
              onChange={({target}) =>
                setOrcamentoItem({
                  ...orcamentoItem,
                  producaoPorVeiculo: target.value,
                })
              }
              error={fieldErro.includes('producaoPorVeiculo')}
              readOnly
            />
          </Grid>
          <Grid item xs={2}>
            <InputV2
              label="Produção total"
              name="producaoTotal"
              value={Number.currencyFormat(
                Number.stringToFloat(orcamentoItem.producaoPorVeiculo || 0) *
                  (orcamentoItem.quantidadeVeiculos || 0),
              )}
              disabled
            />
          </Grid>
        </Grid>
        <Grid sx={styles.actionButtonContainer}>
          <RoundTooltipButton
            id="icon-primary"
            type="button"
            title={'Duplicar'}
            icon={<FileCopyOutlined sx={styles.itemIcon} />}
            sx={styles.itemButton}
            onClick={hasModelo ? onDuplicate : undefined}
            disabled={!hasModelo}
          />
          <RoundTooltipButton
            id="icon-cancel"
            type="button"
            title={'Descartar'}
            icon={<Delete sx={styles.itemIcon} />}
            sx={styles.itemButton}
            onClick={onDelete}
          />
        </Grid>
      </Grid>
    </Formik>
  );
};

const styles = {
  container: {
    display: 'flex',
    paddingLeft: '0px',
    paddingRight: '4px',
    height: 'inherit',
    flexDirection: 'column',
  },
  form: {
    overflowY: 'auto',
    height: 'inherit',
    padding: '12px',
  },
  headerText: {
    fontSize: '14px',
    fontWeight: 900,
    color: '#00106B',
    padding: '5px 0',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 1,
  },
  label: {
    display: 'block',
    fontWeight: 'bold',
    color: '#657496',
    fontSize: '14px',
    marginBottom: '3px',
  },
  value: {
    marginLeft: 1,
    display: 'block',
    color: '#657496',
    fontSize: '14px',
    marginBottom: '3px',
  },
  anunciante: {
    display: 'flex',
  },
  buttons: {
    maxWidth: '185px',
    '@media (min-width: 1024px)': {
      marginLeft: '8px',
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    background: '#fff',
    width: '100%',
    marginTop: 'auto',
    padding: '15px',

    '@media (min-width: 1024px)': {
      flexDirection: 'row',
    },
  },
  cidadesContainer: {
    display: 'flex',
    borderRadius: '4px',
    backgroundColor: '#e4e9f3bf',
    padding: '8px',
    flexDirection: 'column',
    marginBottom: '20px',
  },
  actionButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '112px',
    marginLeft: 1,
  },
  itemIcon: {
    width: 18,
  },
  itemButton: {
    width: 35,
    height: 35,
    minWidth: 35,
    margin: '4px 0 4px 12px!important',
  },
  novoClienteButton: {
    borderRadius: '4px !important',
    margin: '0px -14px 0px 0px',
    backgroundColor: '#afb7c9 !important',
    svg: {
      color: '#fff',
    },
  },
  itemRowContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    padding: '12px',
    borderRadius: '6px',
    background: '#fff',
    marginTop: '8px',
    border: (theme) => `1px solid ${theme.palette.paterns.blueGraySecundary}`,
    '&:first-child': {
      marginTop: '0px',
    },
  },
  tabelaModal: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    padding: '10px 20px',
    top: 50,
    right: 0,
    width: 'max-content',
    height: 'max-content',
    background: '#E5EAF4',
    zIndex: 5,
    borderRadius: '6px',

    '&::before': {
      right: 10,
      top: -12,
      transform: 'scaleX(2)',
      fontSize: 16,
      height: 5,
      content: "'\\25B2'",
      color: '#E5EAF4',
      position: 'absolute',
    },
  },
  tabelaModalTitle: {
    fontSize: 12,
  },
  tabelaModalDesc: {
    fontSize: 10,
    margin: '-4px 0 4px 0',
  },
  tabelaModalValue: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  tabelaModalContainer: {
    width: '1px',
    height: 'initial',
    margin: '0 20px',
    borderLeft: '1px solid #cecece',
  },
};

export default CadastroOrcamento;
