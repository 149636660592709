import {Grid, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography} from '@mui/material';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {ReactComponent as KmMidiaLogo} from '../../assets/images/km-midia-logo.svg';
import Number from '../../utils/number';

const Divisor = require('../../assets/images/divisor-relatorio.png');

const ImpressaoOrcamento = ({orcamento, orcamentoItens}) => {
  const [formData, setFormData] = useState({});
  const [itens, setItens] = useState([]);

  useEffect(() => {
    setFormData(orcamento);
    setItens(orcamentoItens);
  }, [orcamento, orcamentoItens]);

  const numeroCidade = itens.length;
  let totalValorDescontadoTodosVeiculos = 0;
  let totaisVeiculosMesaisTotais = 0;
  let totaisValorTotalCidade = 0;
  let totaisValorTotalCidadeTodosPeriodos = 0;
  let totalKmEstimado = 0;
  let totalImpactosExternosEstimados = 0;
  let totalImpactosInternosEstimados = 0;
  let somaTotaisImpactos = 0;
  const valorReservaTecnica = (formData?.totalVeiculos * 0.1).toFixed();
  const dataProducao = [
    {
      totalVeiculos: formData?.totalVeiculos,
      reservaTecnica: `(10%)`,
      carrosProducaoFinal: Number.safeParseFloat(formData?.totalVeiculos) + Number.safeParseFloat(valorReservaTecnica),
      valorProducaoLiquido: Number.currencyFormat(1.1 * Number.currencyToFloat(formData?.valorTotalProducao)),
    },
  ];
  for (var c = 1; c < formData.quantidadeMeses; c++) {
    dataProducao.push({
      totalVeiculos: 0,
      reservaTecnica: `(10%)`,
      carrosProducaoFinal: valorReservaTecnica,
      valorProducaoLiquido: Number.currencyFormat(0.1 * Number.currencyToFloat(formData?.valorTotalProducao)),
    });
  }
  const somaTotaisVeiculosProducao = dataProducao.reduce(
    (accumulator, currentValue) => accumulator + Number.stringToFloat(currentValue.totalVeiculos),
    0,
  );
  const somaReservaTecnica = Number.stringToFloat(valorReservaTecnica * (formData.quantidadeMeses - 1));
  const somaCarrosProducaoFinal = dataProducao.reduce(
    (accumulator, currentValue) => accumulator + Number.stringToFloat(currentValue.carrosProducaoFinal),
    0,
  );
  const somaProducaoLiquido = dataProducao.reduce(
    (accumulator, currentValue) => accumulator + Number.currencyToFloat(currentValue.valorProducaoLiquido),
    0,
  );

  return (
    <Grid item xs={12} sx={styles.reportContainer}>
      <Grid item xs={12} style={{margin: '0 10px'}}>
        <img alt={'divisor'} style={{width: '100%', margin: '10px 0'}} src={Divisor} />
      </Grid>
      <Grid item xs={12} sx={styles.reportHeader}>
        <Grid item xs={10}>
          <Typography sx={styles.titleReport} style={{fontWeight: '700'}}>
            Proposta:
          </Typography>
          {formData?.anunciante && <Typography sx={styles.titleReport}>{`Cliente: ${formData?.anunciante?.nomeFantasia || ''}`}</Typography>}
          <Typography sx={styles.titleReport}>{`Título da campanha: ${formData?.titulo || ''}`}</Typography>
          <Typography sx={styles.titleReport}>{`Tempo (dias): ${formData?.quantidadeMeses * formData?.diasPeriodo?.value} dias`}</Typography>
        </Grid>
        <Grid item xs={2}>
          <KmMidiaLogo style={{width: '100%'}} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={styles.titleReport} style={{fontWeight: '700'}}>
          Veiculação
        </Typography>
        <Table stickyHeader aria-label="sticky table" sx={styles.tableVeiculacao}>
          <TableHead>
            <TableRow style={{fontWeight: '700'}}>
              <TableCell sx={styles.tableHead} align={'left'}>
                Cidades
              </TableCell>
              <TableCell sx={styles.tableHead} align={'left'}>
                Produto
              </TableCell>
              <TableCell sx={styles.tableHead} align={'left'}>
                Valor unit. bruto
              </TableCell>
              <TableCell sx={styles.tableHead} align={'left'}>
                Nº de carros
              </TableCell>
              <TableCell sx={styles.tableHead} align={'left'}>
                Valor total bruto
              </TableCell>
              <TableCell sx={styles.tableHead} align={'left'}>
                Desconto
              </TableCell>
              {formData?.quantidadeMeses > 1 && (
                <TableCell sx={styles.tableHead} align={'left'}>
                  V. total bruto negociado por período
                </TableCell>
              )}
              <TableCell sx={styles.tableHead} align={'left'}>
                V. total bruto negociado {formData?.quantidadeMeses > 1 ? 'todos os períodos' : ''}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {itens.map((item, index) => {
              const valorTotalMensalVeiculo = Number.stringToFloat(item?.quantidadeVeiculos) * Number.stringToFloat(item?.valorMensalVeiculo);
              const totalNegociadoTodosPeriodos = formData?.quantidadeMeses * Number.stringToFloat(item?.valorTotalCidade);
              const valorDescontadoTodosVeiculos =
                Number.stringToFloat(item?.quantidadeVeiculos) * Number.stringToFloat(item?.valorDescontoPorVeiculo);
              totalValorDescontadoTodosVeiculos = totalValorDescontadoTodosVeiculos + valorDescontadoTodosVeiculos;
              totaisVeiculosMesaisTotais = totaisVeiculosMesaisTotais + valorTotalMensalVeiculo;
              totaisValorTotalCidade = totaisValorTotalCidade + (Number.stringToFloat(item?.valorTotalCidade) || 0);
              totaisValorTotalCidadeTodosPeriodos = totaisValorTotalCidadeTodosPeriodos + totalNegociadoTodosPeriodos;
              return (
                <TableRow tabIndex={-1} key={`itemA${index}`} sx={styles.tableRowPrincipal}>
                  <TableCell>{`${item?.cidade?.nome}/${item?.cidade?.uf}`}</TableCell>
                  <TableCell>{item?.modeloAnuncio?.nome}</TableCell>
                  <TableCell>{item?.valorMensalVeiculo}</TableCell>
                  <TableCell>{item?.quantidadeVeiculos}</TableCell>
                  <TableCell>{Number.currencyFormat(valorTotalMensalVeiculo)}</TableCell>
                  <TableCell style={{whiteSpace: 'nowrap'}}>{`${Number.currencyFormat(valorDescontadoTodosVeiculos)} (${Number.stringToFloat(
                    item?.desconto,
                  )}%)`}</TableCell>
                  {formData?.quantidadeMeses > 1 && <TableCell align={'left'}>{item?.valorTotalCidade}</TableCell>}
                  <TableCell align={'left'}>{Number.currencyFormat(totalNegociadoTodosPeriodos)}</TableCell>
                </TableRow>
              );
            })}
            <TableRow tabIndex={-1} key={`itemAfinal`}>
              <TableCell sx={styles.tableHead} align="left" style={{whiteSpace: 'nowrap'}}>
                Total de cidades: {numeroCidade}
              </TableCell>
              <TableCell />
              <TableCell />
              <TableCell sx={styles.tableHead}>{formData?.totalVeiculos}</TableCell>
              <TableCell sx={styles.tableHead}>{Number.currencyFormat(totaisVeiculosMesaisTotais)}</TableCell>
              <TableCell sx={styles.tableHead}>{Number.currencyFormat(totalValorDescontadoTodosVeiculos)}</TableCell>
              {formData?.quantidadeMeses > 1 && (
                <TableCell sx={styles.tableHead} align="left">
                  {Number.currencyFormat(totaisValorTotalCidade)}
                </TableCell>
              )}
              <TableCell sx={styles.tableHead} align="left">
                {Number.currencyFormat(totaisValorTotalCidadeTodosPeriodos)}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell rowSpan={1} colSpan={8} sx={styles.footerSpace}>
                <Typography sx={styles.footerText} style={{height: 50}}>
                  <br />
                </Typography>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Grid>
      <Grid container sx={styles.tableResumoVeiculação}>
        <Grid item xs={4} sx={styles.tableHead}>
          Valor total da veiculação (Bruto)
        </Grid>
        <Grid item xs={8} sx={styles.tablecellResumoVeiculacao}>
          {Number.currencyFormat(totaisValorTotalCidadeTodosPeriodos)}
        </Grid>
        <Grid item xs={4} sx={styles.tableHead} style={{borderBottom: '1px solid #E0E0E0'}}>
          Valor total da veiculação (Líquido)
        </Grid>
        <Grid item xs={8} sx={styles.tablecellResumoVeiculacao} style={{borderBottom: '1px solid #E0E0E0'}}>
          {Number.currencyFormat(
            Number.currencyToFloat(totaisValorTotalCidadeTodosPeriodos) -
              Number.currencyToFloat(formData?.comissaoAgencia) * formData.quantidadeMeses,
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} style={{breakInside: 'avoid'}} sx={styles.table}>
        <Typography sx={styles.titleReport} style={{fontWeight: '700'}}>
          Produção
        </Typography>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell sx={styles.tableHead}>Periodos</TableCell>
              <TableCell sx={styles.tableHead}>Numero de carros</TableCell>
              <TableCell sx={styles.tableHead}>Reserva Técnica</TableCell>
              <TableCell sx={styles.tableHead}>Total de Carro</TableCell>
              <TableCell sx={styles.tableHead}>Valor total líquido</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataProducao.map((item, index) => {
              return (
                <TableRow tabIndex={-1} key={index} sx={styles.tableRowPrincipal}>
                  <TableCell>Período - {index + 1} </TableCell>
                  <TableCell>{item?.totalVeiculos}</TableCell>
                  <TableCell>{item?.reservaTecnica}</TableCell>
                  <TableCell>{item?.carrosProducaoFinal}</TableCell>
                  <TableCell>{item?.valorProducaoLiquido}</TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell sx={styles.tableHead} align="left">
                Total
              </TableCell>
              <TableCell sx={styles.tableHead} align="left">
                {Number.format(somaTotaisVeiculosProducao)}
              </TableCell>
              <TableCell sx={styles.tableHead} align="left">
                {Number.format(somaReservaTecnica)}
              </TableCell>
              <TableCell sx={styles.tableHead} align="left">
                {Number.format(somaCarrosProducaoFinal)}
              </TableCell>
              <TableCell sx={styles.tableHead} align="left">
                {Number.currencyFormat(somaProducaoLiquido)}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell rowSpan={1} colSpan={7} sx={styles.footerSpace}>
                <Typography sx={styles.footerText} style={{height: 50}}>
                  <br />
                </Typography>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Grid>
      {formData?.observacoes && (
        <Grid item xs={12} sx={styles.observacoes}>
          <Typography sx={styles.tableHead}>Observações:</Typography>
          <Typography component="pre">{formData?.observacoes}</Typography>
        </Grid>
      )}
      <Grid item xs={12} style={{breakInside: 'avoid'}} sx={styles.table}>
        <Typography sx={styles.titleReport} style={{fontWeight: '700'}}>
          Métricas estimadas da campanha:
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={styles.tableHead}>Cidades</TableCell>
              <TableCell sx={styles.tableHead}>Numero de carros contratados</TableCell>
              <TableCell sx={styles.tableHead}>Quilometragem estimada</TableCell>
              <TableCell sx={styles.tableHead}>Impactos externos estimados</TableCell>
              <TableCell sx={styles.tableHead}>Impactos internos estimados</TableCell>
              <TableCell sx={styles.tableHead}>Total de impactos</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {itens.map((item, index) => {
              const diasPeriodo = item.modeloAnuncio?.diasPeriodo || 30;
              const quantidadeMeses = formData?.quantidadeMeses || 1;
              const quantidadeVeiculos = item?.quantidadeVeiculos || 0;
              const kmPeriodo = item?.cidade?.kmMedio || item?.cidade?.kmPadrao || 0;
              const impactoPorKm = item.cidade?.impactadosKm || 30;
              const mediaCorridas = item?.cidade?.mediaCorridas || 25;
              const percentualImpactos = (item.modeloAnuncio?.percentualImpactos || 100) / 100;

              // Cálculo do km estimado considerando o número de meses
              const kmEstimado = (kmPeriodo / 30) * diasPeriodo * quantidadeVeiculos * quantidadeMeses;

              // Cálculo dos impactos externos estimados
              const impactosExternosEstimados = kmEstimado * impactoPorKm * percentualImpactos;

              // Cálculo da média de km por corrida
              const mediaKmPorCorrida = (kmEstimado / quantidadeVeiculos / (diasPeriodo) / mediaCorridas).toFixed(2);

              // Cálculo dos impactos internos estimados, se aplicável
              const impactosInternosEstimados = item.modeloAnuncio?.interno ? (kmEstimado / mediaKmPorCorrida) * 1.4 * quantidadeMeses : 0;

              // Atualização dos totais
              totalKmEstimado += kmEstimado;
              totalImpactosExternosEstimados += impactosExternosEstimados;
              totalImpactosInternosEstimados += impactosInternosEstimados;

              const totalImpactos = impactosExternosEstimados + impactosInternosEstimados;
              somaTotaisImpactos += totalImpactos;
              return (
                <TableRow tabIndex={-1} key={`estimado${index}`} sx={styles.tableRowPrincipal}>
                  <TableCell>{item?.cidade?.nome}</TableCell>
                  <TableCell>{item?.quantidadeVeiculos}</TableCell>
                  <TableCell>{Number.format(Number.safeParseInt(kmEstimado))}</TableCell>
                  <TableCell>{Number.format(Number.safeParseInt(impactosExternosEstimados))}</TableCell>
                  <TableCell>{Number.format(Number.safeParseInt(impactosInternosEstimados))}</TableCell>
                  <TableCell>{Number.format(Number.safeParseInt(totalImpactos))}</TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell sx={styles.tableHead} align="left">
                Total de cidades : {numeroCidade}
              </TableCell>
              <TableCell sx={styles.tableHead} align="left">
                {formData?.totalVeiculos}
              </TableCell>
              <TableCell sx={styles.tableHead} align="left">
                {Number.format(Number.safeParseInt(totalKmEstimado))}
              </TableCell>
              <TableCell sx={styles.tableHead} align="left">
                {Number.format(Number.safeParseInt(totalImpactosExternosEstimados))}
              </TableCell>
              <TableCell sx={styles.tableHead} align="left">
                {Number.format(Number.safeParseInt(totalImpactosInternosEstimados))}
              </TableCell>
              <TableCell sx={styles.tableHead} align="left">
                {Number.format(Number.safeParseInt(somaTotaisImpactos))}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell rowSpan={1} colSpan={7} sx={styles.footerSpace}>
                <Typography sx={styles.footerText} style={{height: 50}}>
                  <br />
                </Typography>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Grid>
      <Grid item xs={12} className={`page-footer`} sx={styles.footer} style={{display: 'none'}}>
        <Grid item xs={12} sx={styles.footerLogoContainer}>
          <KmMidiaLogo style={styles.footerLogo} />
          <Typography sx={styles.footerText}>
            <b>Valorizando sua marca a cada KM rodado!</b>®
          </Typography>
        </Grid>
        <img alt={'divisor'} style={{width: '100%', margin: '5px 0'}} src={Divisor} />
        <Grid container direction="row" style={{width: '100%', justifyContent: 'space-between'}}>
          <Typography sx={styles.footerSubText}>{`Proposta gerado no dia ${moment().format(
            'DD [de] MMMM [de] YYYY [às] HH[h]mm',
          )}, válido por 30 dias`}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = {
  tableVeiculacao: {
    '& .MuiTableCell-root': {
      fontSize: '0.7rem',
      backgroundColor: '#fff',
      borderBottom: 'none',
    },
    '& .MuiTableHead-root': {
      '& .MuiTableCell-root': {
        borderBottom: '1px solid #E0E0E0',
      },
    },
  },
  reportContainer: {
    backgroundColor: '#fff',
    borderRadius: '4px',
    padding: '0 10px',
  },
  titleReport: {
    marginLeft: '15px',
    fontSize: 14,
    color: '#031663',
  },
  reportHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  tableHead: {
    fontWeight: '700',
    fontSize: '0.7rem',
  },
  table: {
    paddingBottom: '100px',
    pageBreakBefore: 'always',
    breakInside: 'avoid',
    '& .MuiTableCell-root': {
      fontSize: '0.7rem',
      backgroundColor: '#fff',
      borderBottom: 'none',
    },
    '& .MuiTableHead-root': {
      '& .MuiTableCell-root': {
        borderBottom: '1px solid #E0E0E0',
      },
    },
  },
  tableResumoVeiculação: {
    breakInside: 'avoid',
    width: '100%',
    paddingBottom: '50px',
  },
  tablecellResumoVeiculacao: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '8px 15px',
    fontSize: '0.7rem',
    lineHeight: '0.8rem',
    backgroundColor: '#fff',
    borderBottom: 'none',
  },
  footer: {
    margin: '0px 10px',
    position: 'fixed',
    width: '100%',
    top: '730px',
  },
  footerSpace: {
    whiteSpace: 'nowrap',
    borderTop: '1px solid #E0E0E0',
  },
  footerLogo: {
    height: '25px',
    width: '110px',
    margin: '6px 20px 0px -5px',
  },
  footerText: {
    margin: '20px 0 0 0',
    fontSize: 14,
    color: '#000',
    textAlign: 'center',
  },
  footerSubText: {
    fontSize: 11,
    paddingRight: '10px',
  },
  footerLogoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  observacoes: {
    backgroundColor: '#cecece',
    breakInside: 'avoid',
    width: '100%',
    marginTop: '10px',
    paddingBottom: '100px',
    zIndex: 999,
  },
  observacoesText: {
    whiteSpace: 'normal',
  },
  tableRowPrincipal: {
    '& .MuiTableCell-root': {
      borderBottom: 'none',
      paddingBottom: '5px',
    },
  },
};

export default ImpressaoOrcamento;
